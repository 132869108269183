//
import { axiosClient } from 'net/ajax'
import { useMutation } from 'react-query'

async function notifySupport(request) {
    const response = await axiosClient.post(
        '/ratesmgr/carriers/notify',
        request
    )

    return response
}

export default function useNotifySupport() {
    return useMutation((data) => notifySupport(data), {
        retry: false,
    })
}
