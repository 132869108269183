//
import React, { Component } from 'react'

export default class FlashMessage extends Component {
    state = {
        dismissed: false,
    }

    getAlertClass(type) {
        switch (type) {
            case 'warning':
                return 'alert-warning'
            case 'error':
                return 'alert-danger'
            default:
                return 'alert-success'
        }
    }

    dismiss = (e) => {
        e.preventDefault()

        this.setState({
            dismissed: true,
        })

        setTimeout(() => {
            this.props.onDismiss(this.props.index)
        }, 300)

        return false
    }

    render() {
        const {
            message: { text, type },
            children,
        } = this.props
        const { dismissed } = this.state

        let wrapperCls = 'alert ' + this.getAlertClass(type) + ' fade'
        if (!dismissed) {
            wrapperCls += ' in'
        }

        return (
            <div className={wrapperCls}>
                <a
                    className="close"
                    data-dismiss="alert"
                    onClick={this.dismiss}
                >
                    ×
                </a>

                {children ? (
                    <div id="flash_notice">{children}</div>
                ) : (
                    <div
                        id="flash_notice"
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                )}
            </div>
        )
    }
}
