//
import React, { useState, useEffect, Fragment } from 'react'
import StepIndicator from 'components/dashboard/integrations/containers/CarrierWizard/components/StepIndicator'
import ValidationActions from './ValidationActions'
import useCarrierTitle from 'components/dashboard/integrations/containers/CarrierWizard/hooks/useCarrierTitle'
import UpgradeNotice from './UpgradeNotice'
import Loader from 'components/Loader'

export default function InstallationSuccessful(props) {
    const [installing, setInstalling] = useState(true)
    const [success, setSuccess] = useState(false)
    const carrierTitle = useCarrierTitle(carrier.name)
    const { carrierEntity, carrierDisabled, carrier, onInstall } = props

    useEffect(() => {
        if (onInstall) {
            onInstall().then((result) => {
                setInstalling(false)
                setSuccess(result)
            })
        }
    }, [])

    return (
        <>
            <h3>{carrierTitle} Installation</h3>

            {installing ? (
                <Loader />
            ) : (
                <SuccessMessage
                    carrierEntity={carrier?.id ? carrier : carrierEntity}
                    carrierDisabled={carrierDisabled}
                    success={success}
                />
            )}
        </>
    )
}

function SuccessMessage(props) {
    const { carrierEntity, carrierDisabled, success } = props

    if (success) {
        return (
            <>
                <div className="success-msg">
                    <StepIndicator total={1} current={1} />
                    <h4>Installation Successful!</h4>
                </div>

                {carrierDisabled && <UpgradeNotice />}

                <ValidationActions
                    carrierEntity={carrierEntity}
                    carrierDisabled={carrierDisabled}
                />
            </>
        )
    }
    return (
        <div className="fail-msg">
            Please try again later or{' '}
            <a href="https://docs.shipperhq.com/contact/">contact us</a> if the
            error persists
        </div>
    )
}
