import React from 'react'
import { findCarrierTitle } from 'components/dashboard/integrations/utils'
import { useCarrierManagement } from '../../carrierManagement'
import { DetailsDescription, DetailsHeader, MarketplaceWrapper } from './styles'

type Props = {
    showModal: () => void
    title?: string
    description?: string
}

export const MarketplaceDetailsCTA = ({
    showModal,
    title,
    description,
}: Props) => {
    const {
        state: { carrierName },
    } = useCarrierManagement()
    const carrierTitle = findCarrierTitle(carrierName)

    const onInstallClick = () => {
        showModal()
    }

    return (
        <MarketplaceWrapper>
            <DetailsHeader>
                {title || 'Instantly display {carrierTitle} rates'}
            </DetailsHeader>
            <DetailsDescription>
                {description ||
                    `Install ${carrierTitle} and display standard list rates to your
                customers. Once FedEx has been installed, qualified merchants
                can enter their account information to display account rates to
                their customers.`}
            </DetailsDescription>
            <button
                data-testid="agree-step-install-button"
                type="button"
                className="btn btn-primary"
                onClick={onInstallClick}
            >
                Install
            </button>
        </MarketplaceWrapper>
    )
}
