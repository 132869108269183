//
import { axiosClient } from 'net/ajax'
import { useMutation, useQueryClient } from 'react-query'

async function createRegistrationWithListRates(carrierId) {
    const request = {
        ratesmgr_ups_registration: {
            carrier_id: carrierId,
            list_rates: true,
        },
        commit: true,
    }

    const response = await axiosClient.post(
        '/ratesmgr/ups_registrations/install',
        request
    )

    return response
}

export default function useCreateRegistration() {
    const queryClient = useQueryClient()
    return useMutation(
        ({ carrierId }) =>
            createRegistrationWithListRates(carrierId),
        {
            onSuccess: (_data, carrierId) => {
                queryClient.invalidateQueries(carrierId)
            },
            retry: false,
        }
    )
}
