export const SHOPIFY = 'shopify'
export const BIGCOMMERCE = 'bigcommerce'
export const WOOCOMMERCE = 'woocommerce'
export const SALESFORCE = 'salesforce'
export const COMMERCETOOLS = 'commercetools'
export const CARTCOM = 'cartcom'
export const MAGENTO2 = 'magento'
export const ADOBECOMMERCE = 'adobecommerce'
export const ZOEY = 'zoey'

export const FEDEX_HAL_PLAN_ID = 10
export const UPS_ACCESS_POINT_PLAN_ID = 9
export const FREE_LITE_PLAN_ID = 11

// Paid plans
export const ADVANCED_PLAN_ID = 13
export const STARTER_PLAN_ID = 2
export const PRO_PLAN_ID = 3
export const ENTERPRISE_PLAN_ID = 4
export const ESSENTIALS_PLAN_ID = 5
export const UPS_DIGITAL_STANDARD_PLAN_ID = 6
export const UPS_DIGITAL_PRO_PLAN_ID = 7
export const UPS_DIGITAL_ENTERPRISE_PLAN_ID = 8
export const STANDARD_PLAN_ID = 12

export const MAGENTO_PLATFORM_ID = 1
export const MAGENTO2_PLATFORM_ID = 8
export const ADOBECOMMERCE_PLATFORM_ID = 10
export const WOOCOMMERCE_PLATFORM_ID = 7
export const ZOEY_PLATFORM_ID = 5

export const isFreePlan = (planId) =>
    planId === FEDEX_HAL_PLAN_ID ||
    planId === UPS_ACCESS_POINT_PLAN_ID ||
    planId === FREE_LITE_PLAN_ID
