//
import React, { useState, useEffect } from 'react'

/**
 * ContinueButton Properties
 */

// TODO: Refactor so disabled is passed in as a prop
function ContinueButton({ errors, onClick, label, disabled: defaultDisabled }) {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if (errors) {
            setDisabled(false)
        }
    }, [errors])

    return (
        <button
            className="btn btn-primary"
            onClick={async (e) => {
                setDisabled(true)
                if (onClick) {
                    try {
                        const result = onClick(e)
                        if (result.then) {
                            await result // wait for promise to resolve
                            setDisabled(false)
                        }
                    } catch (err) {
                        setDisabled(false)
                    }
                }
            }}
            type={onClick ? 'button' : 'submit'}
            disabled={defaultDisabled || disabled}
        >
            {label ? label : 'Continue'}
        </button>
    )
}

export default ContinueButton
