import { useSelector } from 'react-redux'

export function useFirstName() {
    return useSelector<ReduxRootState>(
        ({
            app: {
                current_user: { first_name },
            },
        }) => first_name
    )
}
