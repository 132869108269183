import { createContext, useContext } from 'react'

// $FlowFixMe
export const AgreementContext = createContext({
    agreement: {
        text: null,
        notice: null,
        error: null,
    },
    setAgreementData: (data) => {},
})

export default function useLicenseAgreement() {
    return useContext(AgreementContext)
}
