import { useSelector } from 'react-redux'

export const useCarrierRequiredFeatures = () => {
    const { enabledFeatures, availableIntegrations } = useSelector(
        ({ app: { enabled_features = [], integrations = {} } }: any) => ({
            enabledFeatures: enabled_features,
            availableIntegrations: integrations.available_integrations || [],
        })
    )

    const getCarrierRequiredFeatures = (carrier) => {
        let requiredFeatures = []

        if (
            isLTLRequired({ carrier, availableIntegrations, enabledFeatures })
        ) {
            requiredFeatures = [...requiredFeatures, 'ltl_freight']
        }

        if (isAddressValidationRequired({ carrier, enabledFeatures })) {
            requiredFeatures = [...requiredFeatures, 'validation']
        }

        return requiredFeatures
    }

    return { getCarrierRequiredFeatures }
}

const isLTLRequired = ({ carrier, availableIntegrations, enabledFeatures }) =>
    getCarrierCategory(carrier, availableIntegrations) === 'freight' &&
    !enabledFeatures.includes('ltl_freight')

const isAddressValidationRequired = ({ carrier, enabledFeatures }) =>
    ['experian', 'loqate'].includes(carrier.carrier_type || carrier.name) &&
    !enabledFeatures.includes('validation')

const getCarrierCategory = (carrier, availableIntegrations) => {
    if (carrier.category_id) return carrier.category_id

    if (availableIntegrations) {
        const carrierItem = availableIntegrations.find(
            (item) => item.name === carrier.name
        )

        if (carrierItem) return carrierItem.category_id
    }

    return null
}
