import React, { useContext, useEffect } from 'react'
import Modal from 'components/ui/Modal'
import MultiStepForm from 'components/ui/form/MultiStepForm'
import ValidationStatusContext from 'components/dashboard/integrations/components/ValidationStatusContext'
import {
    submitCarrierRegistrationData,
    useCarrierManagement,
} from 'components/dashboard/integrations/carrierManagement'
import { useCarrierWizardClose } from 'components/dashboard/integrations/containers/CarrierWizard/components/hooks/useCarrierWizardClose'

import { useWizardSteps } from './hooks/use-wizard-steps'
import { MarketplaceDetailsCTA } from '../listRatesService/MarketplaceDetailsCTA'
import { usePreselectDefaultOrigin } from '../listRatesService/hooks/common'

const noop = () => undefined

export const USPSWizard = ({ onClose = noop }) => {
    const { replaceCredentials } = useContext(ValidationStatusContext)

    usePreselectDefaultOrigin()

    const context = useCarrierManagement()
    const { isMarketplace, account } = context

    const { modalVisible, setModalVisible, handleClose } =
        useCarrierWizardClose(onClose)

    const steps = useWizardSteps({ handleClose })

    useEffect(() => {
        // REVIEW: replaceCredentials gets updated from the CarrierDetailsPage
        // and is set true when the update credentials button is clicked
        // in Marketplace. It can also be updated when clicking "Enter Credentials"
        // from the list rates banner. It should have a better variable name
        if (replaceCredentials) {
            setModalVisible(true)
        }
    }, [replaceCredentials])

    let initialStep = 0
    if (replaceCredentials) {
        initialStep = 1
    }

    const handleValidationSubmit = async (data) => {
        await submitCarrierRegistrationData(context, data)
    }

    // prepopulate values for existing account
    const initialValues = {
        account_info: account?.values || {},
    }

    return (
        <>
            {isMarketplace && (
                <MarketplaceDetailsCTA
                    showModal={() => setModalVisible(true)}
                    title="Instantly display USPS® online rates"
                    description="Install USPS® and display online rates to your customers. Once USPS has been installed, qualified merchants can enter their account information to display account rates to their customers."
                />
            )}

            {modalVisible && (
                <div data-testid="usps-wizard">
                    <Modal
                        modalName="mp-install"
                        captureNotifications={false}
                        onClose={handleClose}
                        className="modal-content install-app"
                        hidden={!modalVisible}
                    >
                        <MultiStepForm
                            id="usps-form"
                            trackingKey="mp-install"
                            onSubmit={handleValidationSubmit}
                            dontPersist
                            disableAutocomplete
                            initialStep={initialStep}
                            initialValues={initialValues}
                        >
                            {steps}
                        </MultiStepForm>
                    </Modal>
                </div>
            )}
        </>
    )
}

USPSWizard.defaultProps = {
    onClose: () => undefined,
}
