import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from 'styles'

const AccordionButton = styled.button`
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.grey3};
    background: ${({ theme }) => theme.colors.greyfa};
    border: 1px solid ${({ theme }) => theme.colors.greye9};
    border-radius: 3px;
    width: 466px;
    max-width: 100%;
    padding: 17px 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    ${({ isOpen }) =>
        isOpen
            ? 'border-bottom: none;border-bottom-left-radius: 0;border-bottom-right-radius: 0;'
            : ''};

    &:hover {
        background-color: ${({ theme }) => theme.colors.greye9};
        transition: background-color 0.3s;
    }
`

const Chevron = styled.i`
    transform: ${({ isOpen }) =>
        isOpen ? 'rotate(360deg)' : 'rotate(180deg)'};
    transition: transform 0.3s;
    font-size: 12px;
`

const AccordionContent = styled.div`
    max-height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    overflow: hidden;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transition: max-height 0.3s, opacity 0.3s;
`

export default function Accordian({ label, children }) {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(!isOpen)

    return (
        <Box>
            <AccordionButton
                onClick={toggle}
                isOpen={isOpen}
                aria-expanded={isOpen}
                aria-controls="accordionContent"
                id="accordionButton"
            >
                {label}
                <Chevron className="fas fa-chevron-up" isOpen={isOpen} />
            </AccordionButton>
            <AccordionContent
                isOpen={isOpen}
                id="accordionContent"
                aria-labelledby="accordionButton"
                role="region"
            >
                {children}
            </AccordionContent>
        </Box>
    )
}
