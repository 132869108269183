import { useMutation, useQueryClient } from 'react-query'
import { axiosClient } from 'net/ajax'

type CarrierProps = {
    id: string
    name: string
}

type CredentialsValuesProps = {
    shipper_number: string
}

async function updateCarrierCredentials(carrier: CarrierProps, values: CredentialsValuesProps) {
    const request = {
        ratesmgr_carrier: values,
        account_id: carrier.id,
    }

    const response = await axiosClient.put(
        `/ratesmgr/carriers/${carrier.id}/credentials`,
        request
    )
    return response.data
}

export const useUpdateCarrierCredentials = () => {
    const queryClient = useQueryClient()

    return useMutation<
        unknown,
        unknown,
        { carrier: CarrierProps; values: CredentialsValuesProps }
    >(({ carrier, values }) => updateCarrierCredentials(carrier, values), {
        onSuccess: (_data, { carrier }) => {
            void queryClient.invalidateQueries(carrier.id)
            void queryClient.invalidateQueries(carrier.name)
        },
    })
}
