import React, { useState } from 'react'
import { Button } from 'styles'
import { openBlankOAuthWindow } from './OAuthCarrierButton'
import { registerOauth, verifyOauth } from '../hooks/useUPSOauth'

interface UPSOauthWizardProps {
    accountForm: {
        multiple_accounts: boolean
    }
    carrier: unknown
    onClose: () => void
    account: unknown
}

const OAUTH_CALLBACK_PATH = '/ups_oauth/callback'

export const UPSOauthForm: React.FC<UPSOauthWizardProps> = ({
    accountForm,
    carrier,
    onClose,
    account,
}) => {
    return (
        <>
            <UPSOauthButton
                title="Enter Credentials"
                onClose={onClose}
                carrier={carrier}
                account={account}
            />

            {accountForm?.multiple_accounts && (
                <>
                    <hr />
                    <div className="caption-sm">
                        <strong>Note:</strong> This carrier allows multiple
                        accounts. After validating your first account you will
                        be able to add and validate additional accounts.
                    </div>
                </>
            )}
        </>
    )
}

const UPSOauthButton = ({ title, carrier, account, onClose }) => {
    const [loading, setLoading] = useState(false)
    const carrierId = carrier?.id || account?.id

    const handleError = (error, oauthWindow) => {
        setLoading(false)

        let message = 'Something went wrong'

        if (error.response.status === 401) {
            message =
                'Your session has expired. Please log in again and try to connect your UPS account.'
        }

        oauthWindow.location.href = `${OAUTH_CALLBACK_PATH}?status=failure&error=${message}`
    }

    const handleOAuth = async (event) => {
        event.preventDefault()
        event.stopPropagation()

        setLoading(true)

        const oAuthMessageCallback = (event) => {
            if (event.data.type === 'oauthCode') {
                const code = event.data.code

                return verifyOauth({ carrierId, code })
                    .then(() => {
                        setLoading(false)
                        window.location.href = `/ratesmgr/carriers/${carrierId}/edit`
                        onClose && onClose()
                        oauthWindow.close()
                    })
                    .catch((e) => handleError(e, oauthWindow))
            }
        }

        // For Safari/Mozilla we can't open popup in the async function
        // therefore we need to open it before the async function
        // and later it will be redirected to the OAuth URL
        const oauthWindow = openBlankOAuthWindow()

        observeOauthWindowClose({
            oauthWindow,
            onClose: () => {
                setLoading(false)
                window.removeEventListener('message', oAuthMessageCallback)
            },
        })

        let registerResponse = null

        try {
            registerResponse = await registerOauth({ carrierId })
        } catch (e) {
            return handleError(e, oauthWindow)
        }

        const authorizeUrl = registerResponse.data.authorize_url

        oauthWindow.location.href = authorizeUrl

        // When the user completes UPS Authentification, UPS will redirect to the callback URL
        // We need to observe the URL to get the code to complete the OAuth verification
        window.addEventListener('message', oAuthMessageCallback)
    }

    return (
        <Button
            disabled={loading}
            className="btn btn-primary"
            data-testid="oauthButtonHandler"
            onClick={handleOAuth}
        >
            {title}
        </Button>
    )
}

const observeOauthWindowClose = ({ oauthWindow, onClose }) => {
    const checkWindow = setInterval(function () {
        if (oauthWindow.closed) {
            clearInterval(checkWindow)
            onClose()
        }
    }, 500)
}
