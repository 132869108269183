export function stripSpacesFromFormData(data) {
    const result = {}
    for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'string') {
            result[key] = value.trim()
        } else {
            result[key] = value
        }
    }

    return result
}
