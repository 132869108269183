//
import { createContext, useContext } from 'react'

/**
 * Use this context to pass some extra data to carrier wizards
 * @type {React.Context<{}>}
 */
export const CarrierWizardContext = createContext({})

export function useCarrierWizard() {
    const context = useContext(CarrierWizardContext)
    return context
}
