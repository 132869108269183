//
import React, { useEffect } from 'react'
import RequiresFeature from 'components/dashboard/RequiresFeature'
import history from 'components/frontend/history'
import iconMapper from 'components/dashboard/features/Icons'

export const FEATURE_TITLES = {
    ltl_freight: 'LTL Freight',
    validation: 'Address Type Validation',
}

export const RequiredFeatureWarning = ({
    title,
    onContinue,
    onCancel,
    feature,
    urlHook = true,
}) => {
    useEffect(() => {
        if (!urlHook) return

        const path = location.pathname + `?ul=add-new-live-carrier-requires-ltl`
        history.push(path)
    }, [])

    return (
        <RequiresFeature
            feature={feature}
            onContinue={onContinue}
            onCancel={onCancel}
            logo={iconMapper(feature)}
        >
            <h4 className="normal mb20">
                To enable {title}, the{' '}
                <strong>{FEATURE_TITLES[feature]}</strong> feature must be
                enabled.
            </h4>
        </RequiresFeature>
    )
}
