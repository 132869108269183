import { useSelector } from 'react-redux'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import cx from 'classnames'
import { findCarrierTitle } from 'components/dashboard/integrations/utils'
import { useCarrierManagement } from '../../carrierManagement'
import { StepNavigatorContext } from '../../../../ui/StepNavigator'
import { accountFormAndCanInstallSelector } from '../../containers/CarrierWizard/components/selectors'
import { useInstallListRatesCarrier } from './hooks/common'
import {
    EnterCredentialsButton,
    CustomAccountRequirements,
    DropdownTitle,
    StandardListRatesNote,
    StepContainer,
    SubmitButton,
} from './styles'
import NoDefaultOriginWarning from '../../containers/CarrierWizard/components/NoDefaultOriginWarning'
import CarrierLogo from '../../../carriers/containers/NewCarrierWizard/components/CarrierLogo'
import useLoader from '../../../scopes/hooks/useLoader'
import { useOriginsList } from 'components/dashboard/hooks/useOriginsList'

export const InstallListRates = ({ onClickFinish, noAccountDetails, submitButtonLabel }) => {
    const {
        carrier,
        state: { carrierName },
    } = useCarrierManagement()

    const loader = useLoader()
    const { Icon: LoaderIcon } = loader
    const doInstall = useInstallListRatesCarrier()

    const { nextStep } = useContext(StepNavigatorContext)

    const { accountForm, canInstall } = useSelector(
        accountFormAndCanInstallSelector
    )

    const [isAccountDetailsOpen, showAccountDetails] = useState(false)
    const carrierTitle = findCarrierTitle(carrierName)
    const origins = useOriginsList()
    const doInstallCarrier = useCallback(
        loader.wrap(async () => {
            await doInstall()
        }),
        []
    )

    useEffect(() => {
        if (canInstall && origins.length) {
            // create carrier immediately if we can
            doInstallCarrier()
        }
    }, [])

    if (!origins || !origins.length) {
        return <NoDefaultOriginWarning />
    }

    if (loader.loading) {
        return <LoaderIcon />
    }

    return (
        <StepContainer>
            <h3>
                {carrierTitle} was installed <strong>successfully</strong>
            </h3>

            <CarrierLogo carrier={carrier} isLive isSuccess />

            <StandardListRatesNote>
                {carrierTitle} retail rates will be displayed
            </StandardListRatesNote>

            {!noAccountDetails && <CustomAccountRequirements>
                <DropdownTitle
                    isOpen={isAccountDetailsOpen}
                    onClick={() => showAccountDetails(!isAccountDetailsOpen)}
                >
                    Would you like to display your {carrierTitle} account rates?
                    <i
                        className={cx('fa', {
                            'fa-chevron-up': isAccountDetailsOpen,
                            'fa-chevron-down': !isAccountDetailsOpen,
                        })}
                    />
                </DropdownTitle>

                {isAccountDetailsOpen && (
                    <>
                        <ul
                            dangerouslySetInnerHTML={{
                                __html:
                                    accountForm?.instructions || 'Loading...',
                            }}
                        />

                        <hr />

                        <EnterCredentialsButton
                            onClick={nextStep}
                            className="btn"
                        >
                            Enter account information
                        </EnterCredentialsButton>

                        {accountForm?.help_doc_url && (
                            <a
                                href={accountForm.help_doc_url}
                                target="_blank"
                                rel="noreferrer noopener"
                                className="help-txt"
                                data-testid="help-doc-link"
                            >
                                How to obtain {carrierName} credentials
                            </a>
                        )}
                    </>
                )}
            </CustomAccountRequirements>}

            <SubmitButton
                onClick={onClickFinish}
                className="btn btn-primary"
                role="submit-button"
            >
                { submitButtonLabel || 'Finish' }
            </SubmitButton>
        </StepContainer>
    )
}
