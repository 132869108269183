import { useSelector } from 'react-redux'
import {
    isFreePlan,
} from 'components/frontend/signup/constants'

/**
 * Hook to determine what user is allowed to do based on plan/etc
 * @param capability
 */
export default function useUserCapability(capability) {
    const { planId, hasProServices } = useSelector(
        ({
            app: {
                // eslint-disable-next-line camelcase
                current_user: { plan_id = 0, has_pro_services = false } = {},
            } = {},
        }) => ({
            // eslint-disable-next-line camelcase
            planId: plan_id,
            // eslint-disable-next-line camelcase
            hasProServices: has_pro_services,
        })
    )

    switch (capability) {
        case 'pro_services':
            return hasProServices

        case 'custom_carriers':
            return !isFreePlan(planId)

        /**
         * Display Explore Features in the trial wizard?
         */
        case 'explore_features': {
            return !isFreePlan(planId)
        }

        /**
         * Some plans don't allow multiple origins
         */
        case 'multiple_origins': {
            return !isFreePlan(planId)
        }

        /**
         * Some plans don't allow multiple carriers
         */
        case 'multiple_carriers': {
            return !isFreePlan(planId)
        }

        default:
            return false
    }
}

export function usePlanId() {
    return useSelector(
        // eslint-disable-next-line camelcase
        ({ app: { current_user: { plan_id = 0 } = {} } = {} }) => plan_id
    )
}
