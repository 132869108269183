import { createSelector } from 'reselect'

export const accountFormSelector = (state) =>
    state?.app?.integrations?.account_form || {}
export const canInstallSelector = (state) =>
    state?.app?.integrations?.can_install
export const userSettings = (state) => state?.app?.user_settings
export const currencyCodeSelector = (state) =>
    userSettings(state)?.currency_code?.toUpperCase()

export const availableIntegrationsSelector = (state) =>
    state?.app?.integrations?.available_integrations

export const accountFormAndCanInstallSelector = createSelector(
    [accountFormSelector, canInstallSelector],
    (accountForm, canInstall) => ({
        accountForm,
        canInstall,
    })
)
