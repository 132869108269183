import { useSelector } from 'react-redux'
import React, { useContext, useEffect, useMemo } from 'react'
import ValidationStatusContext from '../../../components/ValidationStatusContext'
import WizardHeaderContext from '../../../containers/CarrierWizard/components/WizardHeaderContext'
import {
    FEDEX_RATE_TYPES,
    updateFedexRateType,
    useCarrierManagement,
} from '../../../carrierManagement'
import { currencyCodeSelector } from '../../../containers/CarrierWizard/components/selectors'
import { useCarrierWizard } from '../../../CarrierWizardContext'
import { useSupportsListRates } from './common'
import { InstallListRates } from '../InstallListRates'
import { InstallAccountRates } from '../InstallAccountRates'
import { ValidationStep2 } from '../ValidationStep2'
import { ValidationStep3 } from '../ValidationStep3'

export function useWizardSteps({ handleClose }) {
    const { replaceCredentials } = useContext(ValidationStatusContext)
    const { isInstallSuccess = false } = useContext(WizardHeaderContext)
    const context = useCarrierManagement()
    const currencyCode = useSelector(currencyCodeSelector)
    const {
        isMarketplace,
        state: {
            carrier,
            carrierExists = false,
            carrierEntity,
            fedexRateType: rateType,
            fedexRateTypeSelected: rateTypeSelected = false,
        } = {},
    } = context
    const { skipFedexListRatesSwitch } = useCarrierWizard()
    const supportsListRates = useSupportsListRates()

    // Determines if Fedex Rate type selector page is visible
    const displayRateSelector = useMemo(() => {
        if (replaceCredentials) return false
        if (!supportsListRates) return false // show selector only for 'fedEx' carrier

        // don't display rate type selector when the carrier already exists and we are invoking wizard on the Marketplace
        if (!isInstallSuccess) {
            // this is set when carrier is added from the carrier index page
            if (
                (carrierExists && isMarketplace) ||
                (carrierExists && !isMarketplace)
            ) {
                return false
            }
        }

        const standardRateSelected =
            rateTypeSelected && rateType === FEDEX_RATE_TYPES.standard

        // Fedex List Rates will only get rates correctly for shops
        // using USD
        return (
            (!rateTypeSelected || standardRateSelected) &&
            currencyCode?.toUpperCase() === 'USD'
        )
    }, [])

    useEffect(() => {
        // set default fedex rate type
        let newRateType = FEDEX_RATE_TYPES.standard

        if (
            skipFedexListRatesSwitch ||
            replaceCredentials ||
            !supportsListRates
        ) {
            newRateType = FEDEX_RATE_TYPES.userNegotiated
        }

        if (carrierEntity?.list_rates) {
            newRateType = FEDEX_RATE_TYPES.standard
        }

        updateFedexRateType(context, newRateType)
    }, [])

    /// /////////////////////////////
    //  Populate carrier wizard steps
    return useMemo(() => {
        const steps = []

        if (supportsListRates) {
            steps.push(
                <InstallListRates key="step1" onClickFinish={handleClose} />
            )
        } else {
            steps.push(<InstallAccountRates key="step1" />)
        }
        steps.push(<ValidationStep2 key="step2" />)
        steps.push(<ValidationStep3 key="step3" />)

        return steps
    }, [displayRateSelector, rateType, carrier])
}
