import React from 'react';
import { BannerCarriersTall } from './Images';
import styles from './CustomCarrierBanner.module.css'

interface CustomCarrierBannerProps {
  smallBanner?: boolean
}

export const CustomCarrierBanner = ({ smallBanner = false }: CustomCarrierBannerProps) => {
  return (
    <div className={`${styles.bannerBox} ${smallBanner ? styles.smallBanner : ''} max-cont`} data-testid="custom-carrier-banner">
      <div className={styles.bannerText}>
        <h4 className={styles.header}>Don’t see your carrier? Create a custom carrier.</h4>
        {!smallBanner && <p>Custom carriers allow you to define rates for any carrier based on your unique needs.</p>}

        <a href="/ratesmgr/carriers?ul=add-new-carrier" className={styles.link}>Add a custom carrier <i className="fas fa-arrow-right"></i></a>
      </div>

      <img src={BannerCarriersTall} alt="" className={styles.image} />
    </div>
  )
}

