//
import React, { Fragment } from 'react'

/**
 * StepIndicator Properties
 */

const StepIndicator = ({ current, total, failure, warning }) => (
    <div className="step-indicator">
        <div className="line" />

        {generateSteps(current, total).map(({ active, last }, idx) => (
            <Fragment key={'step' + idx}>
                {renderIndicator({ idx, active, failure, last, warning })}
            </Fragment>
        ))}
    </div>
)

export default StepIndicator

function generateSteps(current, total) {
    const result = []

    for (let i = 0; i < total; i++) {
        result.push({
            active: i < current,
            last: i === current - 1,
        })
    }

    return result
}

function renderIndicator({ idx, active, failure, last, warning }) {
    let line = null
    if (idx > 0) {
        line = (
            <span
                className={
                    'step' + (active && !failure && !warning ? ' active' : '')
                }
            />
        )
    }

    let className =
        last && failure
            ? 'fa failure fa-times-circle'
            : active && !failure && !warning
            ? 'fa active ' + (last ? 'fa-check-circle last' : 'fa-circle')
            : 'fa fa-circle'

    if (last && active && !failure && warning) {
        className =
            'fa caution fa-exclamation-triangle' + (active ? ' last' : '')
    }

    return (
        <Fragment>
            {line}
            <span className={className} />
        </Fragment>
    )
}
