import styled from 'styled-components'
import { space, color, border, typography } from 'styled-system'
import CarrierLogo from "../../../carriers/containers/NewCarrierWizard/components/CarrierLogo";

export const StepContainer = styled.div`
    margin: 0 auto;
    max-width: 466px;
    .carrier-selected {
        background: #fff;
    }
`

export const StandardListRatesNote = styled.div`
    margin-top: -55px;
    ${border};
    ${color};
    ${space};
    ${typography};
`

StandardListRatesNote.displayName = 'StandardListRatesNote'

StandardListRatesNote.defaultProps = {
    border: 'green2',
    borderRadius: '3px',
    py: 15,
    pt: 18,
    px: 12,
    mb: 8,
    bg: 'greenlt1',
    fontWeight: 'bold',
    fontSize: 'xl',
}

export const CustomAccountRequirements = styled.div`
    ${color};
    ${space};
    ${border};
    ${typography};

    margin-top: ${({ withTopGap }) => (withTopGap ? '-55px' : 'inherit')};
    padding-top: ${({ withTopGap }) => (withTopGap ? '55px' : 'inherit')};
    margin-bottom: 20px;

    ul {
        list-style: none;
        margin: 20px auto;
        max-width: 320px;
        font-size: ${({ theme }) => theme?.fontSizes?.instructions};
    }

    li {
        text-align: left;
        position: relative;
        padding-left: 24px;
        margin-bottom: 15px;

        &:before {
            font-family: 'Font Awesome 5 Pro';
            content: '\\f00c';
            position: absolute;
            top: 0;
            left: 0;
            height: 1em;
            width: 1em;
            font-size: 16px;
            color: ${({ theme }) => theme?.colors?.greybluelt};
        }

        &:last-of-type {
            margin-bottom: 5px;
        }
    }

    a.help-txt {
        display: block;
        margin-top: 13px;
        margin-bottom: 30px;
        font-size: 14px;
        color: ${({ theme }) => theme?.colors?.greybluelt1};

        &:before {
            content: '\\f05a';
            font-family: 'Font Awesome 5 Pro';
            font-weight: 400;
            margin-right: 5px;
        }
    }
`

CustomAccountRequirements.displayName = 'CustomAccountRequirements'
CustomAccountRequirements.defaultProps = {
    py: 4,
    bg: 'greyfa',
    borderRadius: '3px',
    border: 'greye9',
}

export const DropdownTitle = styled.span`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0;
    ${space};
    ${typography};

    i {
        font-size: 12px;
    }
`
DropdownTitle.defaultProps = {
    py: 7,
    px: 12,
    fontWeight: 'semi',
    fontSize: 'medium',
}

export const MarketplaceWrapper = styled.div`
    text-align: left;
`

export const DetailsHeader = styled.h4`
    font-weight: 700;
    font-size: 18px;
`
export const DetailsDescription = styled.div`
    margin-bottom: 12px;
`
export const SubmitButton = styled.button`
    margin-top: 12px;
`

export const EnterCredentialsButton = styled.button`
    ${typography};

    font-weight: bold;
    background: #fff;
    color: ${({ theme }) => theme?.colors?.greybluelt};
    border-color: ${({ theme }) => theme?.colors?.greybluelt};
    &:hover {
        color: ${({ theme }) => theme?.colors?.greybluelt};
    }
`

export const CarrierLogoNew = styled(CarrierLogo)`
    .carrier-selected {
        background-color: #fff;
    }
`
