import React from 'react'
import { logoStyle } from 'components/dashboard/integrations/containers/Index/components/utils'
import useLogos from 'hooks/useLogos'

export default function CarrierLogo({ carrier, isLive, isSuccess = false }) {
    const logos = useLogos()

    if (!carrier) {
        return null
    }

    const { name, title } = carrier || {}
    return (
        <div className="c-message">
            <div className="c-named">
                <div
                    className={
                        'carrier-selected carrier-name-' +
                        name +
                        ' ' +
                        (isLive ? 'c-type-live' : 'c-type-custom')
                    }
                    {...logoStyle(logos, name)}
                >
                    {logos && logos[name] && (
                        <img src={logos[name]} title={title} alt={title} />
                    )}
                    {isSuccess && (
                        <i className="fas fa-check-circle ico-success" />
                    )}
                </div>
            </div>
        </div>
    )
}
