import { useRailsRoutes } from 'utils/railsRoutes'
import logger from 'utils/logger'
import { useCarrierManagement } from 'components/dashboard/integrations/carrierManagement'

export default function useMPNavigation(carrierEntityOverride = null) {
    const routes = useRailsRoutes()
    const context = useCarrierManagement()
    const { state: { carrierEntity = null } = {} } = context

    const entity = carrierEntityOverride ? carrierEntityOverride : carrierEntity

    function goToCarrierEntityPage() {
        if (!entity) {
            logger.error('carrierEntity is null in goToCarrierEntityPage()')
        } else {
            window.location = routes.edit_ratesmgr_carrier_path(entity.id)
        }
    }

    return { goToCarrierEntityPage }
}
