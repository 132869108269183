import React, { useMemo } from 'react'
import { useCarrierManagement } from '../../../carrierManagement'
import { useSupportsListRates } from '../../listRatesService/hooks/common'
import { InstallUSPSListRates } from '../InstallListRates'
import { InstallUSPSAccountRates } from '../InstallAccountRates'
import { ValidationStep2 } from '../../listRatesService/ValidationStep2'
import { ValidationStep3 } from '../../listRatesService/ValidationStep3'

function useUSPSSupportsListRates() {
    const context = useCarrierManagement()
    const { state: { carrier } = {} } = context
    const supportsListRates = useSupportsListRates()

    return useMemo(() => supportsListRates, [carrier])
}

export function useWizardSteps({ handleClose }) {
    const supportsListRates = useUSPSSupportsListRates()

    /// /////////////////////////////
    //  Populate carrier wizard steps
    return useMemo(() => {
        const steps = []

        if (supportsListRates) {
            steps.push(
                <InstallUSPSListRates key="step1" onClickFinish={handleClose} />
            )
        } else {
            steps.push(<InstallUSPSAccountRates key="step1" />)
        }

        steps.push(<ValidationStep2 key="step2" />)
        steps.push(<ValidationStep3 key="step3" />)

        return steps
    }, [supportsListRates])
}
