const registrationForm = {
    fields: [
        {
            name: 'fedexAccountNumber',
            title: 'FedEx Account Number',
            validate: ['nonempty', 'number'],
            hint: null,
        },
        {
            name: 'phoneNumber',
            title: 'Phone Number',
            validate: 'nonempty',
        },
        {
            name: 'customerFirstName',
            title: 'First Name',
            validate: 'nonempty',
        },
        {
            name: 'customerLastName',
            title: 'Last Name',
            validate: 'nonempty',
        },
        {
            title: 'Address',
            hint:
                'This is the address that is used for your FedEx account billing',
            type: 'section',
            columns: [
                [
                    {
                        name: 'address_street',
                        title: 'Street Address',
                        validate: 'nonempty',
                    },
                    {
                        name: 'address_street2',
                        title: 'Address Line 2 (optional)',
                    },
                    {
                        name: 'address_city',
                        title: 'City',
                        validate: 'nonempty',
                    },
                    {
                        name: 'address_region',
                        title: 'State',
                        type: 'state',
                        initial_value: 'AL',
                        country_field: 'address_country',
                    },
                    {
                        name: 'address_zipcode',
                        title: 'Zip/Postal Code',
                        validate: 'nonempty',
                    },
                    {
                        name: 'address_country',
                        title: 'Country',
                        type: 'country',
                        initial_value: 'US',
                        option_source: 'countries',
                    },
                ],
            ],
        },
    ],
}

export default registrationForm
