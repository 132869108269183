import store from 'reducers/store'
import { CarrierModel } from './containers/CarrierWizard/types'
import Carrier from './containers/Index/components/Carrier'

export function isSICategory(categoryId): boolean {
    return [
        'insurance',
        'tech_partner',
        'platforms',
        'logistics_services',
        'solutions_integrator',
    ].includes(categoryId)
}

export function getAccountNumber(carrier: CarrierModel): string {
    if (carrier.carrier_type === 'ups') {
        return carrier.shipper_number
    } else {
        return carrier.carrier_user_id
    }
}

// DEPRECATED
export function findCarrierTitle(name): string {
    const {
        app: { integrations: { available_integrations = [] } = {} },
    } = store.getState()

    if (name === 'upsFreight') {
        return 'TForce Freight'
    }

    const carrier =
        available_integrations &&
        available_integrations.find((carrier) => carrier.name === name)

    if (carrier) {
        return carrier.title
    } else {
        return name
    }
}

export const isListRatesCarrier = (carrier) => {
    return ['ups', 'fedEx', 'usps'].includes(carrier?.name)
}

const upsNames = [
    'ups',
    'surePost',
    'accessPoint',
    'upsGroundFreight',
    'upsMyChoice',
]

export function isUPSCarrier(carrier: Carrier): boolean {
    if (!carrier) {
        return false
    }

    const name = carrier.type ? carrier.type : carrier.name
    return upsNames.includes(name)
}

const upsWizardNames = ['ups', 'surePost', 'accessPoint', 'upsGroundFreight']

export function requiresUPSCarrierWizard(carrier): boolean {
    if (!carrier) {
        return false
    }

    const name = carrier.type ? carrier.type : carrier.name

    return upsWizardNames.includes(name)
}

const fedexNames = [
    'fedex',
    'fedexlocation',
    'smartpost',
    // 'fedexfreight',
    // 'fedexsameday',
]

export function isFedexCarrier(carrier: Carrier): boolean {
    if (!carrier) {
        return false
    }

    const carrierName = carrier.type ? carrier.type : carrier.name

    return fedexNames.includes(carrierName?.toLowerCase())
}

export function isUSPSCarrier(carrier: Carrier) {
    if (!carrier) {
        return false
    }

    const carrierName = carrier.type ? carrier.type : carrier.name

    return 'usps' === carrierName?.toLowerCase()
}

export function isTForceFreightCarrier(carrier: Carrier) {
    if (!carrier) {
        return false
    }

    return 'tforceFreight' === carrier.name
}

function isTForceUPSFreightCarrier(carrier: Carrier) {
    if (!carrier) {
        return false
    }

    return 'upsFreight' === carrier.name
}

export function isOauthSupportCarrier(carrier: Carrier) {
    return (
        isTForceFreightCarrier(carrier) ||
        isTForceUPSFreightCarrier(carrier) ||
        isUPSCarrier(carrier)
    )
}
