//
import React, { Component, Fragment } from 'react'
import MultiStepForm from 'components/ui/form/MultiStepForm'
import FormStep from 'components/ui/form/FormStep'
import Modal from 'components/ui/Modal'
import { StepNavigatorContext } from 'components/ui/StepNavigator'
import ValidationHandler from './ValidationHandler'
import BackButton from './BackButton'
import BottomNotes from './BottomNotes'
import { AgreementContext } from '../hooks/useLicenseAgreement'
import { axiosClient } from 'net/ajax'

import {
    findCarrierTitle,
    isUPSCarrier,
    isOauthSupportCarrier,
} from 'components/dashboard/integrations/utils'
import DynamicForm from 'components/ui/form/DynamicForm'
import { connect } from 'react-redux'
import { pushAppData } from 'reducers/modules/app'
import { markValidated } from 'reducers/modules/integrations'
import ValidationStatusContext from 'components/dashboard/integrations/components/ValidationStatusContext'
import ContinueButton from './ContinueButton'
import OriginSelector from './OriginSelector'
import NoDefaultOriginWarning from './NoDefaultOriginWarning'
import InstallationSuccessful from 'components/dashboard/integrations/containers/CarrierWizard/components/InstallationSuccessful'
import UpgradePlanFooter from './UpgradePlanFooter'
import { extractErrorCause } from '../errors'
import { stripSpacesFromFormData } from './forms'
import InstallationError from 'components/dashboard/integrations/containers/CarrierWizard/components/InstallationError'
import UShipFirstStep from './UShipFirstStep'
import WizardHeader, {
    EnterCredentialsNote,
} from 'components/dashboard/integrations/containers/CarrierWizard/components/WizardHeader'
import Loader from 'components/Loader'
import logger from 'utils/logger'
import {
    OAuthCarrierButton,
    OAuthCreateCarrierButton,
} from './OAuthCarrierButton'

/**
 * CarrierWizard Properties
 */

/**
 * CarrierWizard State
 */

/**
 */
export class GenericWizard extends Component {
    static contextType = ValidationStatusContext

    static defaultProps = { inline: false, onClose: () => {} }

    constructor(props) {
        super(props)

        this.state = {
            accountNumber: '',
            carrierEntity: props.account ? props.account : null,
            modalVisible: false,
            success: false,
            carrierDisabled: false,
            installationError: false,
            errorCause: null,
            canInstall: props.canInstall,
            networkAction: false,
            formSubmitted: false,
            isSkipAndEnterLaterProcessing: false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        let result = { ...state }

        // display uShip when dimship feature is disabled
        if (
            state.displayUShip === undefined &&
            props.carrier &&
            props.carrier.name === 'uShip'
        ) {
            const { enabledFeatures } = props
            if (!enabledFeatures.includes('dimship')) {
                result.displayUShip = true
            } else {
                result.displayUShip = false
            }
        } else {
            if (state.displayUShip === undefined) {
                result.displayUShip = false
            }
        }

        if (
            props.account &&
            (!state.carrierEntity || !state.carrierEntity.id)
        ) {
            result.carrierEntity = props.account
        }

        if (
            !state.selectedOrigin &&
            props.optionSources &&
            props.optionSources.origins &&
            props.optionSources.origins.length
        ) {
            result.selectedOrigin = props.optionSources.origins[0].value
        }

        return result
    }

    componentDidMount() {
        // skip to the second step when user clicks on "View and Update Credentials"
        const { replaceCredentials } = this.context
        if (replaceCredentials) {
            this.setState({
                modalVisible: true,
            })
        }
    }

    autoInstallCarrier = async () => {
        await this.install(undefined, {
            nextStep: () => {},
        })

        await this.onSubmit({ account_info: {} })

        return this.state.success
    }

    onClose = (e) => {
        if (e) {
            e.preventDefault()
        }

        this.setState({ modalVisible: false })

        if (this.state.formSubmitted) {
            if (this.context.setInstalled) {
                this.context.setInstalled({
                    ...this.state.carrierEntity,
                    validated: this.state.success,
                })
            }

            if (this.state.success) {
                this.props.markValidated(
                    this.props.carrier,
                    this.state.carrierEntity
                )
            }
        }

        this.props.onClose(true)
        window.location.reload()
    }

    installCarrier = async (callback) => {
        const {
            carrier: { name },
            independent,
        } = this.props
        const { title: accountTitle, id: accountId } = this.props.account
            ? this.props.account
            : {}

        const { setInstalled = (entity) => {} } = this.context

        const carrierData = {
            name,
            title: accountTitle ? accountTitle : findCarrierTitle(name),
            id: undefined,
        }

        if (accountId) {
            carrierData.id = accountId
        }

        const result = await this.props.onInstall(carrierData, independent)

        if (result.type === 'integrations/INSTALL_SUCCESS') {
            const carrierEntity = result.payload.data

            return new Promise((resolve) => {
                this.setState(
                    {
                        carrierEntity,
                        installationError: false,
                        errorCause: '',
                        transactionId: null,
                    },
                    () => resolve(true)
                )

                setInstalled(carrierEntity)

                callback && callback(carrierEntity)
            })
        } else {
            const { error: { response: { data: { errors } = {} } = {} } = {} } =
                result

            this.setState({
                installationError: true,
                errorCause: extractErrorCause({ name }, result),
                validationErrors: errors,
                transactionId: null,
            })
        }
    }

    submitCarrierData = async (data) => {
        const { account_form, inline } = this.props

        const accountNumber =
            data.account_info[account_form.account_number_field]

        const result = await this.postRegistrationData(data.account_info)

        if (typeof result !== 'number') {
            if (result.installed) {
                this.setState({
                    accountNumber,
                    success: true,
                    carrierDisabled: !result.can_activate,
                    contactSupport: null,
                    transactionId: null,
                    carrierEntity: {
                        ...this.state.carrierEntity,
                        ...data.account_info,
                    },
                })

                if (this.context.setValidated) {
                    this.context.setValidated(true)
                }

                // mark it validated (on the integrations page only, otherwise modal will disappear immediately on the carrier page)
                if (!inline) {
                    if (this.context.setInstalled) {
                        this.context.setInstalled({
                            ...this.state.carrierEntity,
                            validated: this.state.success,
                        })
                    }

                    this.props.markValidated(
                        this.props.carrier,
                        this.state.carrierEntity
                    )
                }

                if (result.validation_hint) {
                    this.props.pushAppData({
                        validation_hint: result.validation_hint,
                    })
                }
            } else {
                if (this.context.setValidated) {
                    this.context.setValidated(false)
                }

                this.setState({
                    success: false,
                    validationErrors: result.errors,
                    contactSupport: result.contact_support,
                    transactionId: result.transaction_id,
                    carrierDisabled: false,
                    selectedOrigin: 0,
                })
            }
        } else {
            const { displayError } = this.props
            if (displayError) {
                displayError(
                    `Failed to validate carrier, please recheck your credentials`
                )
            }

            if (this.context.setValidated) {
                this.context.setValidated(false)
            }

            this.setState({
                success: false,
                carrierDisabled: false,
                selectedOrigin: 0,
            })
        }
    }

    onSubmit = async (data) => {
        this.setState({
            networkAction: true,
        })

        const installResult = await this.installCarrier()
        if (installResult) {
            await this.submitCarrierData(data)
        } else {
            logger.error('error not handled!')
        }

        this.setState({
            networkAction: false,
        })
    }

    install = async (e, context) => {
        const { nextStep } = context

        e && e.preventDefault()

        if (this.props.onInstall) {
            if (this.props.inline) {
                this.setState({ modalVisible: true })
            }

            nextStep()
        } else {
            logger.error(
                "Can't install carrier: property onInstall was not set"
            )
        }
    }

    submitAccountInfo = async (values) => {
        let result = { ...values }
        result.region = result.state
        delete result.state

        await this.postRegistrationData(result)
    }

    postRegistrationData = async (data) => {
        const { carrier } = this.props
        const { carrierEntity } = this.state

        if (!carrierEntity) {
            throw new Error(
                'carrierEntity is null while posting registration data'
            )
        }

        this.setState({
            formSubmitted: true,
        })

        const request = {
            ratesmgr_carrier: {
                ...stripSpacesFromFormData(data), // force validation
                active: 0,
                invalid_credentials: 1,
                origin_ids: [this.state.selectedOrigin],
            },
        }

        const carrierId = carrier.id || carrierEntity.id

        try {
            const result = await axiosClient.put(
                `/ratesmgr/carriers/${carrierId}/install`,
                request
            )

            if (result && result.status === 200) {
                return result.data
            }

            const { displayError } = this.props
            if (displayError) {
                displayError(`Unexpected server error occured`)
            }

            return 1
        } catch (e) {
            if (!e.response) {
                throw e
            }

            logger.error('error posting registration data:', e.response.data)

            const { displayError } = this.props
            if (displayError) {
                displayError(e.response.data.error)
            }

            return e.response.data
        }
    }

    modal = (body) => {
        if (this.props.inline && !this.state.modalVisible) {
            return null
        }
        return (
            <Modal
                modalName="mp-install"
                captureNotifications={false}
                onClose={this.onClose}
                className="modal-content install-app"
            >
                {body}
            </Modal>
        )
    }

    renderOAuthButton = () => {
        const { carrier, account, onClose } = this.props

        return (
            <div>
                {account?.carrier?.id ? (
                    <OAuthCarrierButton
                        title="Enter Credentials"
                        carrier={carrier}
                        account={account}
                        onClose={onClose}
                    />
                ) : (
                    <OAuthCreateCarrierButton
                        title="Enter Credentials"
                        onClose={onClose}
                        createCarrier={this.installCarrier}
                    />
                )}

                {this.renderSkipButton()}
            </div>
        )
    }

    renderStep1Install() {
        const { inline, account_form, carrier, account, onClose } = this.props
        const { canInstall } = this.state

        if (!canInstall) {
            // RIV-264: FedEx HAL shouldn't count towards carrier limit
            if (carrier?.name !== 'fedExLocation') {
                return <UpgradePlanFooter />
            }
        }

        if (account_form && !account_form.instructions) {
            return (
                <div className="notice">
                    <strong>Server Error:</strong> This carrier is not
                    configured correctly.
                </div>
            )
        }

        if (isOauthSupportCarrier(carrier) && !isUPSCarrier(carrier)) {
            return this.renderOAuthButton()
        }

        return (
            <Fragment>
                <StepNavigatorContext.Consumer>
                    {(context) => {
                        const { setInstallHandler = (Function) => {} } =
                            this.context

                        setInstallHandler(() =>
                            this.install(undefined, context)
                        )

                        return (
                            <>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={(e) => this.install(e, context)}
                                >
                                    {inline ? 'Enter Credentials' : 'Continue'}
                                </button>
                                {this.renderSkipButton()}
                            </>
                        )
                    }}
                </StepNavigatorContext.Consumer>
            </Fragment>
        )
    }

    doBeginValidation = (context) => {
        const { inline } = this.props

        if (!inline) {
            context.nextStep()
        } else {
            this.setState({ modalVisible: true })
        }
    }

    renderBeginValidation = () => {
        return (
            <StepNavigatorContext.Consumer>
                {(context) => (
                    <>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={(e) => {
                                this.doBeginValidation(context)
                            }}
                        >
                            Enter Credentials
                        </button>
                        {this.renderSkipButton()}
                    </>
                )}
            </StepNavigatorContext.Consumer>
        )
    }

    skipAndEnterLater = async () => {
        this.setState({ isSkipAndEnterLaterProcessing: true })
        const installResult = await this.installCarrier()
        this.setState({ isSkipAndEnterLaterProcessing: false })

        if (installResult) {
            const { carrierEntity } = this.state
            const { carrier } = this.props

            const carrierId = carrier.id || carrierEntity.id

            if (!carrierEntity) {
                logger.error('carrierEntity is null in skipAndEnterLater()')
                return
            }

            try {
                window.location =
                    window.Routes.edit_ratesmgr_carrier_path(carrierId)
            } catch (e) {
                console.error('error in skipAndEnterLater:', e)
            }
        } else {
            logger.error('failed to create carrier!')
        }
    }

    renderSkipButton = () => {
        return (
            <a
                data-testid="skipAndEnterLater"
                href="#"
                className="sub-action"
                onClick={(e) => {
                    e.preventDefault()
                    if (this.state.isSkipAndEnterLaterProcessing) return false
                    this.skipAndEnterLater()
                    return false
                }}
            >
                Skip & Enter Later
            </a>
        )
    }

    renderCarrierCredentialsDetails = () => {
        const { account, carrier } = this.props
        const { account_form } = this.props

        return (
            <CarrierCredentialsDetails
                accountForm={account_form}
                carrier={carrier}
                isNew={!account}
            />
        )
    }

    renderStep1 = (submitError) => {
        const {
            account,
            carrier,
            optionSources: { origins } = {},
            account_form: { auto_install } = {},
        } = this.props
        const title = findCarrierTitle(carrier.name)
        const { account_form } = this.props
        const { installationError, canInstall, errorCause, displayUShip } =
            this.state

        if (displayUShip) {
            return this.renderUShip()
        }

        if (auto_install) {
            return (
                <Fragment>
                    <WizardHeader
                        carrierTitle={title}
                        validationRequired={false}
                        carrier={carrier}
                    />
                    <StepNavigatorContext.Consumer>
                        {(context) => (
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    this.doBeginValidation(context)
                                }}
                            >
                                Install
                            </button>
                        )}
                    </StepNavigatorContext.Consumer>
                </Fragment>
            )
        }

        if (!origins || !origins.length) {
            return <NoDefaultOriginWarning />
        }

        return (
            <Fragment>
                {this.renderCarrierCredentialsDetails()}

                {installationError && canInstall && (
                    <InstallationError
                        carrier={carrier}
                        errorCause={errorCause}
                    />
                )}

                {isOauthSupportCarrier(carrier) && !isUPSCarrier(carrier)
                    ? this.renderOAuthButton()
                    : account && account.id
                    ? this.renderBeginValidation()
                    : this.renderStep1Install()}

                {account_form && account_form.multiple_accounts && (
                    <Fragment>
                        <hr />
                        <div className="caption-sm">
                            <strong>Note:</strong> This carrier allows multiple
                            accounts. After validating your first account you
                            will be able to add and validate additional
                            accounts.
                        </div>
                    </Fragment>
                )}
            </Fragment>
        )
    }

    renderAccountInfoStep = ({ values, handleSubmit, hasValidationErrors }) => {
        const { carrier, inline, account, onClose } = this.props
        const title = findCarrierTitle(carrier.name)
        const isUPS = isUPSCarrier(carrier)

        // OAuth carriers flow
        if (isOauthSupportCarrier(carrier) && !isUPS) {
            return (
                <Fragment>
                    {this.renderCarrierCredentialsDetails()}

                    <OAuthCarrierButton
                        title="Update Credentials"
                        carrier={carrier}
                        account={account}
                        onClose={onClose}
                    />
                </Fragment>
            )
        }

        return (
            <Fragment>
                {!inline && <BackButton />}

                <h3>{title} Validation</h3>

                <h4>Account Info</h4>

                {this.renderAccountForm(
                    values,
                    handleSubmit,
                    hasValidationErrors
                )}

                {isUPS && <BottomNotes />}
            </Fragment>
        )
    }

    renderAccountForm = (values, handleSubmit, hasValidationErrors) => {
        const { account_form, optionSources, userSettings, account, carrier } =
            this.props
        let wrapClass = 'account-info-form compact-form'

        if (account_form?.fields?.length === 1) {
            wrapClass += ' single-field'
        }

        var carrierName
        carrierName = carrier.name

        return (
            <Fragment>
                <div className={wrapClass}>
                    <OriginSelector
                        origins={optionSources.origins}
                        onSelect={(originId) => {
                            this.setState({
                                selectedOrigin: originId,
                            })
                        }}
                        selected={account ? account.origin_ids : []}
                        carrierId={carrierName}
                    />
                    <DynamicForm
                        tab={account_form}
                        values={values}
                        optionSources={optionSources}
                        userSettings={userSettings}
                        forceRowStyle="col-md-6 xsmini-col"
                        noAutocomplete={true}
                    />
                </div>
                <ContinueButton
                    errors={hasValidationErrors}
                    onClick={handleSubmit}
                    label="Validate"
                />
            </Fragment>
        )
    }

    getFormInitialValues = () => {
        const { account_form } = this.props
        const result = {}

        if (account_form && account_form.fields) {
            const reducer = (_, field) => {
                if (field.initial_value) {
                    result[field.name] = field.initial_value
                }

                if (Array.isArray(field.fields)) {
                    field.fields.reduce(reducer, {})
                }
            }

            account_form.fields.reduce(reducer, {})
        }

        return result
    }

    renderUShip = () => {
        const { setInstallHandler = () => {} } = this.context
        const onClose = this.props
        /* $FlowFixMe */
        setInstallHandler(() => {
            this.setState({ displayUShip: false })
        })

        return (
            <UShipFirstStep
                onContinue={() => this.setState({ displayUShip: false })}
                onCancel={onClose}
            />
        )
    }

    renderAllSteps = () => {
        const { carrier } = this.props
        const { carrierEntity, validationErrors, networkAction } = this.state

        return [
            <FormStep name="step1" key={'step1'}>
                {({ submitError }) => this.renderStep1(submitError)}
            </FormStep>,
            <FormStep
                name="account_info"
                initialValues={this.getFormInitialValues()}
                last={true}
                key={'account'}
            >
                {({ values, handleSubmit, hasValidationErrors }) =>
                    this.renderAccountInfoStep({
                        values,
                        handleSubmit,
                        hasValidationErrors,
                    })
                }
            </FormStep>,
            <FormStep
                name={this.state.success ? 'success' : 'fail'}
                key="success"
            >
                {() => {
                    if (networkAction) {
                        return <Loader />
                    }

                    return carrierEntity ? (
                        <ValidationHandler
                            carrier={carrier}
                            carrierEntity={carrierEntity}
                            success={this.state.success}
                            contactSupport={this.state.contactSupport}
                            validationErrors={validationErrors}
                            carrierDisabled={this.state.carrierDisabled}
                            transactionId={this.state.transactionId}
                            closeModal={this.onClose}
                        />
                    ) : (
                        <InstallationError
                            carrier={carrier}
                            errorCause={
                                validationErrors
                                    ? validationErrors.join(', ')
                                    : 'Unknown error.'
                            }
                        />
                    )
                }}
            </FormStep>,
        ].filter((step) => step !== null)
    }

    renderLastStep = () => {
        const { carrier } = this.props
        const { carrierEntity, validationErrors } = this.state

        return (
            /* $FlowFixMe */
            <InstallationSuccessful
                carrier={carrier}
                carrierEntity={carrierEntity}
                success={this.state.success}
                validationErrors={validationErrors}
                carrierDisabled={this.state.carrierDisabled}
                onInstall={this.autoInstallCarrier}
            />
        )
    }

    render() {
        const { replaceCredentials } = this.context
        const {
            carrier,
            inline,
            account_form: { auto_install } = {},
            account,
        } = this.props
        const formProps = {}

        if (inline || replaceCredentials) {
            formProps.initialStep = 1
        }

        formProps.initialValues =
            account && account.values
                ? { account_info: account.values }
                : undefined

        return (
            <div data-testid="generic-wizard">
                {inline && this.renderStep1()}

                {this.modal(
                    auto_install ? (
                        this.renderLastStep()
                    ) : (
                        <AgreementContext.Provider value={this.state}>
                            <MultiStepForm
                                id={carrier.name}
                                trackingKey="mp-install"
                                onSubmit={this.onSubmit}
                                dontPersist={true}
                                disableAutocomplete={true}
                                {...formProps}
                            >
                                {this.renderAllSteps()}
                            </MultiStepForm>
                        </AgreementContext.Provider>
                    )
                )}
            </div>
        )
    }
}

export const CarrierCredentialsDetails = ({
    accountForm,
    carrier,
    isNew,
    totalSteps = null,
    currentStep = null,
    withBack = false,
    wizardTitle = null,
}) => {
    const title = findCarrierTitle(carrier.name)

    return (
        <Fragment>
            <WizardHeader
                wizardTitle={wizardTitle}
                carrierTitle={title}
                validationRequired={true}
                isNew={isNew}
                carrier={carrier}
                totalSteps={totalSteps}
                currentStep={currentStep}
                withBack={withBack}
            />
            <div className="reqs-list">
                <EnterCredentialsNote />
                <ul
                    dangerouslySetInnerHTML={{
                        __html: accountForm
                            ? accountForm.instructions
                            : 'Loading...',
                    }}
                />
                {accountForm && accountForm.help_doc_url && (
                    <a
                        href={accountForm.help_doc_url}
                        target="_blank"
                        rel="noopener"
                        className="help-txt"
                    >
                        How to obtain {title} credentials
                    </a>
                )}
            </div>
        </Fragment>
    )
}

export default connect(
    ({
        app: {
            option_sources,
            user_settings,
            integrations: { account_form, can_install } = {},
            enabled_features,
        },
        app,
    }) => {
        return {
            account_form,
            optionSources: option_sources,
            userSettings: user_settings,
            canInstall: can_install,
            enabledFeatures: enabled_features,
        }
    },
    {
        markValidated,
        pushAppData,
    }
)(GenericWizard)
