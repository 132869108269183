import Accordian from 'components/ui/Accordian'
import { ModalSuccess } from 'components/ui/ModalSuccess'
import React from 'react'
import styled from 'styled-components'
import { Button, Heading } from 'styles'
import BottomNotes from './BottomNotes'
import useUserCapability from 'hooks/useUserCapability'

const ListRatesSuccessBox = styled.div`
    max-width: 466px;
    margin: 0 auto;
`

const SuccessTitle = styled.p`
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 0;
`

const AccordionBody = styled.div`
    color: ${({ theme }) => theme.colors.grey3};
    background: ${({ theme }) => theme.colors.greyfa};
    border: 1px solid ${({ theme }) => theme.colors.greye9};
    border-radius: 3px;
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    text-align: left;
    padding: 0 24px 24px;
    font-size: 14px;
`

const AccordionListTitle = styled.p`
    font-weight: 700;
`

const AccordionList = styled.ul`
    margin: 0;
    list-style: none;
`

const AccordionListItem = styled.li`
    position: relative;
    padding-left: 24px;
    margin-bottom: 15px;

    &:before {
        content: '\f00c';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0;
        height: 1em;
        width: 1em;
        color: ${({ theme }) => theme.colors.greybluelt};
    }
`

const AccordionSubContent = styled.div`
    text-align: center;
`

const AccordionBodyButton = styled.button`
    display: block;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.greybluelt1};
    border: 1px solid ${({ theme }) => theme.colors.greybluelt1};
    background: transparent;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.colors.greybluelt1};
        color: ${({ theme }) => theme.colors.white};
    }
`

const AccordionBodyLink = styled.a`
    color: ${({ theme }) => theme.colors.greybluelt1};

    & i {
        margin-right: 4px;
    }
`

export default function ListRatesFormSuccess({
    onAccountInfoClick,
    onFinishClick,
}) {
    const canHaveAccountRates = useUserCapability('custom_carriers')
    return (
        <ListRatesSuccessBox>
            <Heading level={2} m={0}>
                UPS&reg; was installed <strong>successfully</strong>
            </Heading>
            <ModalSuccess
                showCheck={false}
                showImage={true}
                imagePath={'/images/carriers/app-ups.png'}
            >
                <SuccessTitle>
                    UPS published daily rates will be displayed
                </SuccessTitle>
            </ModalSuccess>
            {canHaveAccountRates && (
                <Accordian label="Would you like to display your UPS account rates?">
                    <AccordionBody>
                        <AccordionListTitle>
                            To display your account rates, you will need:
                        </AccordionListTitle>
                        <AccordionList>
                            <AccordionListItem>
                                A UPS account&nbsp;
                                <AccordionBodyLink
                                href="https://www.ups.com/get-discounted-rates?WT.mc_id=Ready_ShipperHQ_100016"
                                target="_blank"
                                rel="noopener"
                            >
                                (Open a new account with discounts today)
                            </AccordionBodyLink>
                            </AccordionListItem>
                            <AccordionListItem>
                                An active 6-digit UPS account number
                            </AccordionListItem>
                            <AccordionListItem>
                                Country and postal codes associated with UPS
                                account
                            </AccordionListItem>
                            <AccordionListItem>
                                Most recent UPS invoice
                            </AccordionListItem>
                        </AccordionList>

                        <hr />

                        <AccordionSubContent>
                            <div className="flex-center">
                                <AccordionBodyButton
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={onAccountInfoClick}
                                >
                                    Enter account information
                                </AccordionBodyButton>
                            </div>
                            <AccordionBodyLink
                                href="https://docs.shipperhq.com/ups-carrier-setup/#Requirements"
                                target="_blank"
                                rel="noopener"
                            >
                                <i className="fal fa-info-circle" />
                                How to obtain your UPS&reg; account information
                            </AccordionBodyLink>

                    </AccordionSubContent>
                    </AccordionBody>
                </Accordian>
            )}
            <Button
                type="button"
                onClick={onFinishClick}
                className="btn btn-primary"
                mt={40}
            >
                Configure this carrier
            </Button>

            <BottomNotes />
        </ListRatesSuccessBox>
    )
}
