//
import React, { Component, Fragment } from 'react'

/**
 * ValidationErrors Properties
 */

const ValidationErrors = ({ errors }) =>
    errors && errors.length ? (
        <span dangerouslySetInnerHTML={{ __html: renderErrors(errors) }} />
    ) : (
        <Fragment>
            <p>
                Please ensure you have accurate account information.{' '}
            </p>
        </Fragment>
    )

export default ValidationErrors

function renderErrors(validationErrors) {
    return (
        validationErrors.join(' ') +
        '. Please ensure you have accurate account information.'
    )
}
