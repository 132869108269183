import React, { useState } from 'react'
import { useCarrierManagement } from '../../carrierManagement'
import { useModal } from '../../../../ui/Modal'
import useMPNavigation from '../../containers/CarrierWizard/hooks/useMPNavigation'
import FormStep from '../../../../ui/form/FormStep'
import ValidationHandler from '../../containers/CarrierWizard/components/ValidationHandler'
import InstallationError from '../../containers/CarrierWizard/components/InstallationError'
import { useSupportsListRates } from './hooks/common'
import logger from 'utils/logger'

const ValidationNotify = ({ onContinueWithListRatesClick }) => {
    const [loading, setLoading] = useState(false)

    async function doContinue() {
        setLoading(true)

        try {
            await onContinueWithListRatesClick()
        } catch (e) {
            logger.error(e)
        }

        setLoading(false)
    }

    return (
        <>
            <div className="info-msg max-w500">
                <i className="fa fa-paper-plane" />
                <p>
                    Support has been notified about your validation issue. We
                    will be in touch as soon as possible to help you get this
                    carrier setup and receiving account rates. Until then, you
                    are able to use the standard Fedex List Rates
                </p>
            </div>
            <button
                type="button"
                className="btn btn-primary"
                onClick={doContinue}
                disabled={loading}
            >
                Continue with List Rates
            </button>
        </>
    )
}

export const ValidationStep3 = () => {
    const context = useCarrierManagement()
    const {
        carrier,
        state: {
            carrierEntity,
            validationErrors,
            carrierDisabled = false,
            validationSuccess = false,
            transactionId,
        } = {},
    } = context
    const supportsListRates = useSupportsListRates()
    const modal = useModal()
    const { goToCarrierEntityPage } = useMPNavigation()

    const continueWithListRates = async () => {
        // carrier was automatically switched back to list rates at this point
        goToCarrierEntityPage()
    }

    const continueWithListRatesPage = supportsListRates
        ? () => (
              <ValidationNotify
                  onContinueWithListRatesClick={continueWithListRates}
              />
          )
        : null

    return (
        <FormStep name="validation-status">
            {() => (
                <div data-testid="validation3">
                    {carrierEntity && (
                        <ValidationHandler
                            carrier={carrier}
                            carrierEntity={carrierEntity}
                            success={validationSuccess}
                            validationErrors={validationErrors}
                            transactionId={transactionId}
                            carrierDisabled={carrierDisabled}
                            closeModal={modal.onClose}
                            renderNotify={continueWithListRatesPage}
                        />
                    )}
                    {!carrierEntity && (
                        <InstallationError
                            carrier={carrier}
                            errorCause={
                                validationErrors
                                    ? validationErrors.join(', ')
                                    : 'Unknown error.'
                            }
                        />
                    )}
                </div>
            )}
        </FormStep>
    )
}
