import { useState } from 'react'
import {
    installCarrier,
    useCarrierManagement,
} from '../../../carrierManagement'
import useOptionSources from '../../../../../../hooks/useOptionSources'

export function useSupportsListRates() {
    const { carrier } = useCarrierManagement()
    const [supportsListRates] = useState(
        () => carrier?.name?.toLowerCase() === 'fedex'
    )

    return supportsListRates
}

export function useOriginsList() {
    const optionSources = useOptionSources()
    return optionSources?.origins || []
}

export function useInstallFedExListRates() {
    const context = useCarrierManagement()
    const {
        state: { carrierEntity },
    } = context

    return async () => {
        // if carrier already exists - don't create
        if (carrierEntity?.id) {
            return
        }

        const options = {
            list_rates: true,
            active: 0,
            invalid_credentials: 1,
            origin_ids: [],
        }

        return await installCarrier(context, options)
    }
}
