import { useMutation, useQueryClient } from 'react-query'
import { axiosClient } from 'net/ajax'
import { useCarrierTitleResolver } from './useCarrierTitle'

async function createCarrier(
    carrier,
    isIndependent,
    titleResolver: ReturnType<typeof useCarrierTitleResolver>
) {
    const request = {
        ratesmgr_carrier: {
            carrier_type: carrier.name,
            name: titleResolver(carrier.name),
            credentials_owner: isIndependent ? 'INDEPENDENT' : 'OWNER',
            active: 0,
        },
        account_id: carrier.id,
    }

    const response = await axiosClient.post('/ratesmgr/carriers', request)
    return response.data
}

export default function useCreateCarrier() {
    const queryClient = useQueryClient()
    const titleResolver = useCarrierTitleResolver()

    return useMutation<unknown, unknown, { carrier: any; isIndependent: any }>(
        ({ carrier, isIndependent }) =>
            createCarrier(carrier, isIndependent, titleResolver),
        {
            onSuccess: (_data, { carrier }) => {
                void queryClient.invalidateQueries(carrier.id)
                void queryClient.invalidateQueries(carrier.name)
            },
        }
    )
}
