import React from 'react'
import { InstallListRates } from '../listRatesService/InstallListRates'
import { useCarrierManagement } from 'components/dashboard/integrations/carrierManagement'

export const InstallUSPSListRates = ({ onClickFinish }) => {
    const { state: { carrierEntity } } = useCarrierManagement()

    const handleFinish = () => {
        onClickFinish()
        window.location = window.Routes.edit_ratesmgr_carrier_path(carrierEntity.id)
    }

    return (
        <InstallListRates
            onClickFinish={handleFinish}
            noAccountDetails
            submitButtonLabel="Configure this carrier"
        />
    )
}
