//
import React from 'react'

/**
 * CredentialsHeader Properties
 */

export default function CredentialsHeader(props) {
    const { carrierTitle, fedexListRatesStepVisible } = props

    return fedexListRatesStepVisible ? (
        <h3>{carrierTitle} Account Credentials</h3>
    ) : (
        <h3>{carrierTitle} was installed successfully</h3>
    )
}
