import { useEffect, useState } from 'react'
import {
    installCarrier,
    selectOrigin,
    useCarrierManagement,
} from '../../../carrierManagement'
import { isUSPSCarrier } from '../../../utils'
import { useOriginsList } from '../../../../hooks/useOriginsList'

export function useSupportsListRates() {
    const { carrier } = useCarrierManagement()
    const [supportsListRates] = useState(() => isUSPSCarrier(carrier))
    return supportsListRates
}

export function useInstallListRatesCarrier() {
    const context = useCarrierManagement()

    return async () => {
        // if carrier already exists - don't create
        if (context?.state?.carrierEntity?.id) {
            return
        }

        const options = {
            list_rates: true,
            active: 0,
            invalid_credentials: 0,
            origin_ids: [],
        }

        return await installCarrier(context, options)
    }
}

export function usePreselectDefaultOrigin() {
    const context = useCarrierManagement()
    const origins = useOriginsList()

    useEffect(() => {
        const validOrigins = origins.filter((origin) => origin.value)

        if (validOrigins.length === 1) {
            selectOrigin(context, validOrigins[0].value)
        }
    }, [origins])
}
