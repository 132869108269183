//

/**
 * extracts error cause in text form from the carrier create response
 * @param result
 * @returns {string}
 */
export function extractErrorCause(carrier, result) {
    let errorCause = ''

    if (result.error && result.error.response && result.error.response.data) {
        if (typeof result.error.response.data === 'object') {
            const { name, status, error, ...rest } = result.error.response.data
            if (name) {
                errorCause = `Carrier with name ${carrier.name} already exists. Please rename the existing carrier.`
            }

            if (rest) {
                for (const [key, value] of Object.entries(rest)) {
                    errorCause += ` <b>${key}</b>: ${String(value)}.`
                }
            }
        }
    }

    if (!errorCause.length) {
        errorCause = null
    }

    return errorCause
}
