//
import React from 'react'
import { Field } from 'react-final-form'
import { fieldInput, fieldRadio } from 'components/ui/form/renderers'

const accountNumber = (value) =>
    value
        ? value.replace(/[^\d\w\W]+/g, '').length === 6
            ? undefined
            : 'Please enter your 6-digit account number'
        : 'Required'

export default function AccountInfoFormFields({
    withInvoiceField = true,
} = {}) {
    return (
        <>
            <div className="row mb20">
                <Field
                    title="Shipping Account Number (6 characters)"
                    validate={accountNumber}
                    name="account_number"
                    component={fieldInput}
                    fullWidth={true}
                    className="col-md-6 col-md-offset-3"
                />
            </div>

            {withInvoiceField && (
                <div className="row">
                    <h5>Were you invoiced in the last 90 days?</h5>
                    <div className="btn-group-vertical btn-group-lg">
                        <Field
                            name="was_invoiced"
                            type="radio"
                            component={fieldRadio}
                            value="true"
                            labelClassName="btn btn-default"
                            title="Yes, invoiced in the past 90"
                        />
                        <Field
                            name="was_invoiced"
                            type="radio"
                            component={fieldRadio}
                            value="false"
                            labelClassName="btn btn-default"
                            title="No, NOT invoiced in the past 90"
                        />
                    </div>
                </div>
            )}
        </>
    )
}
