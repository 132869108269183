import React, { useContext, useEffect } from 'react'
import Modal from 'components/ui/Modal'
import MultiStepForm from 'components/ui/form/MultiStepForm'
import ValidationStatusContext from 'components/dashboard/integrations/components/ValidationStatusContext'
import {
    submitFedexRegistrationData,
    useCarrierManagement,
} from 'components/dashboard/integrations/carrierManagement'
import { useCarrierWizardClose } from 'components/dashboard/integrations/containers/CarrierWizard/components/hooks/useCarrierWizardClose'

import { useWizardSteps } from './hooks/use-wizard-steps'
import { MarketplaceDetailsCTA } from './MarketplaceDetailsCTA'

const noop = () => undefined

export const FedexWizard = ({ onClose = noop }) => {
    const { replaceCredentials } = useContext(ValidationStatusContext)

    const context = useCarrierManagement()
    const {
        isMarketplace,
    } = context

    const { modalVisible, setModalVisible, handleClose } =
        useCarrierWizardClose(onClose)

    const steps = useWizardSteps({ handleClose })

    useEffect(() => {
        // REVIEW: replaceCredentials gets updated from the CarrierDetailsPage
        // and is set true when the update credentials button is clicked
        // in Marketplace. It can also be updated when clicking "Enter Credentials"
        // from the list rates banner. It should have a better variable name
        if (replaceCredentials) {
            setModalVisible(true)
        }
    }, [replaceCredentials])

    let initialStep = 0
    if (replaceCredentials) {
        initialStep = 1
    }

    const handleValidationSubmit = async (data) => {
        await submitFedexRegistrationData(context, data)
    }

    return (
        <>
            {isMarketplace && (
                <MarketplaceDetailsCTA
                    showModal={() => setModalVisible(true)}
                />
            )}

            {modalVisible && (
                <div data-testid="fedex-wizard">
                    <Modal
                        modalName="mp-install"
                        captureNotifications={false}
                        onClose={handleClose}
                        className="modal-content install-app"
                        hidden={!modalVisible}
                    >
                        <MultiStepForm
                            id="fedex-form"
                            trackingKey="mp-install"
                            onSubmit={handleValidationSubmit}
                            dontPersist
                            disableAutocomplete
                            initialStep={initialStep}
                        >
                            {steps}
                        </MultiStepForm>
                    </Modal>
                </div>
            )}
        </>
    )
}

FedexWizard.defaultProps = {
    onClose: () => undefined,
}
