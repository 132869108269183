//
import React from 'react'
import { upgradePlanURL } from 'utils/urls'

const UpgradeNotice = () => (
    <div className="validation-notice">
        <h5>Your carrier is disabled</h5>
        <p>
            You have exceeded your plan's carrier limit. You can proceed to
            configure the carrier but you will need to{' '}
            <a href={upgradePlanURL()}>upgrade your plan</a> or disable other
            carriers before you can use it.
        </p>
    </div>
)

export default UpgradeNotice
