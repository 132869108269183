//
import React, { useState } from 'react'
import logger from '../../../../utils/logger'

function LoaderIcon() {
    return (
        <div className="loader" role="loader" data-testid="loader">
            <i
                className="fa fa-spinner fa-spin"
                aria-hidden="true"
                style={{
                    color: '#008cbe',
                    fontSize: '64px',
                }}
            />
        </div>
    )
}

function NoIcon() {
    return null
}

export default function useLoader() {
    const [loading, setLoading] = useState(false)

    const start = () => setLoading(true)
    const stop = () => setLoading(false)

    return {
        start,
        stop,
        loading,
        Icon: loading ? LoaderIcon : NoIcon,
        wrap: (action) => async () => {
            start()

            try {
                await action()
            } catch (e) {
                logger.error(`Error: ${e.toString()}`)
            }

            stop()
        },
    }
}
