//
import { useContext, useState } from 'react'
import { markValidated } from 'reducers/modules/integrations'
import { useCarrierManagement } from 'components/dashboard/integrations/carrierManagement'
import ValidationStatusContext from 'components/dashboard/integrations/components/ValidationStatusContext'

/**
 * This hook should be used by the carrier wizards to fire when modal is closed
 * @param onClose
 * @return {{modalVisible: boolean, setModalVisible: (function(): void), handleClose: handleClose}}
 */
export function useCarrierWizardClose(onClose) {
    const context = useCarrierManagement()
    const { setInstalled } = useContext(ValidationStatusContext)

    const {
        carrier,
        isMarketplace,
        dispatchRedux,
        state: {
            installed = false,
            carrierEntity = null,
            validationSuccess = false,
        } = {},
    } = context

    const [modalVisible, setModalVisible] = useState(!isMarketplace)

    const handleClose = (e) => {
        /* $FlowFixMe */
        e?.preventDefault()

        setModalVisible(false)

        if (installed) {
            if (setInstalled) {
                setInstalled({
                    ...carrierEntity,
                    validated: validationSuccess,
                })
            }

            if (validationSuccess) {
                dispatchRedux(markValidated(carrier, carrierEntity))
            }
        } else {
            if (setInstalled) {
                setInstalled({
                    ...carrierEntity,
                    validated: validationSuccess,
                })
            }
        }

        onClose(validationSuccess)

        if (!isMarketplace) {
            // reload the page when on the edit carrier page, so the status would update
            // TODO: optimize it so we won't have to reload the whole page (reloadAppData() doesn't work properly for some reason)
            window.location.reload()
        }
    }

    return {
        modalVisible,
        setModalVisible,
        handleClose,
    }
}
