//
import React, { Fragment } from 'react'

/**
 * NoDefaultOriginWarning Properties
 */

const NoDefaultOriginWarning = (props) => (
    <Fragment>
        <h3>Cannot Add a Carrier</h3>
        <div className="validation-notice">
            <h5>Default Origin Needed</h5>
            <p>
                Before you can add any carriers you will need to first add or
                assign a default origin to your account.
            </p>
        </div>
        <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
                e && e.preventDefault()
                window.location.href = '/ratesmgr/origins'
                return false
            }}
        >
            Go to Origins
        </button>
    </Fragment>
)

export default NoDefaultOriginWarning
