import React, { Component } from 'react'
import CarrierWizard from 'components/dashboard/integrations/containers/CarrierWizard'
import { fetchAppData } from 'reducers/modules/app'
import { installCarrier } from 'reducers/modules/integrations'
import { connect } from 'react-redux'
import WizardHeaderContext from 'components/dashboard/integrations/containers/CarrierWizard/components/WizardHeaderContext'

/**
 */
export class IndependentSetup extends Component {
    static defaultProps = {
        isNewlyCreated: true,
    }

    componentDidMount() {
        const { skipAppDataLoading, available_integrations } = this.props

        // fetch app data, since we need current_user at least
        if (!skipAppDataLoading && !available_integrations?.length) {
            // only load it if there is no marketplace data loaded, otherwise can overwrite what's being loaded in subsequent requests
            this.props.fetchAppData('/ratesmgr/marketplace', true, false)
        }
    }

    render() {
        const {
            carrier,
            installCarrier,
            current_user,
            onClose,
            available_integrations,
            account,
            isNewlyCreated,
        } = this.props

        if (!current_user || !available_integrations) {
            return null
        }

        // NOTE: It took a while to figure out WizardHeaderContext was responsible
        // for displaying the install success message. We probably don't need to
        // use context here and would be better off using a custom hook or a simpler
        // solution
        return (
            <WizardHeaderContext.Provider
                value={{ isInstallSuccess: isNewlyCreated }}
            >
                <CarrierWizard
                    carrier={{
                        ...carrier,
                    }}
                    account={account}
                    onInstall={installCarrier}
                    independent={true}
                    onClose={onClose}
                />
            </WizardHeaderContext.Provider>
        )
    }
}

export default connect(
    ({
        app: { current_user, integrations: { available_integrations } = {} },
    }) => ({
        current_user,
        available_integrations,
    }),
    { installCarrier, fetchAppData }
)(IndependentSetup)
