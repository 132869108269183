import React from 'react'
import { Button } from 'styles'
import { useSelector } from 'react-redux'

export const OAuthCarrierButton = ({
    onClose,
    carrier,
    account,
    title,
}: {
    onClose: () => void
    carrier: Carrier
    account: any
    title: string
}) => {
    const { entity } = useSelector(({ app }: any) => app)

    const oAuthUrl = account?.oauth_url || entity?.data?.oauth_url
    const carrierId = account?.carrier?.id || entity?.data?.id || account?.id

    if (!carrier || !oAuthUrl) {
        return null
    }

    const handleOAuthWindow = (event) =>
        openOAuthWindow({
            event,
            oAuthUrl,
            onClose,
            onCloseUrl: `/ratesmgr/carriers/${carrierId}/edit`,
        })

    return (
        <Button
            className="btn btn-primary"
            data-testid="oauthButtonHandler"
            onClick={handleOAuthWindow}
        >
            {title}
        </Button>
    )
}

export const OAuthCreateCarrierButton = ({
    onClose,
    title,
    createCarrier,
}: {
    onClose: () => void
    createCarrier: any
    title: string
}) => {
    const handleCreateCarrier = async (event) => {
        event.preventDefault()
        event.stopPropagation()

        // For Safari/Mozilla we can't open popup in the async function
        // therefore we need to open it before the async function
        // and later it will be redirected to the OAuth URL
        const oauthWindow = openBlankOAuthWindow()

        return await createCarrier((carrierEntity) => {
            redirectOauthWindow({
                oAuthUrl: carrierEntity.oauth_url,
                onClose,
                onCloseUrl: `/ratesmgr/carriers/${carrierEntity.id}/edit`,
                oauthWindow,
            })
        })
    }

    return (
        <Button
            className="btn btn-primary"
            data-testid="oauthButtonHandler"
            onClick={handleCreateCarrier}
        >
            {title}
        </Button>
    )
}

const popUpConfig = {
    width: 660,
    height: 800,
    heightOffset: 30,
}

type ObserveWindowCloseProps = {
    onClose?: () => void
    oauthWindow: Window
    onCloseUrl: string
}

const observeWindowClose = ({
    onClose,
    oauthWindow,
    onCloseUrl,
}: ObserveWindowCloseProps) => {
    // Ensure the previous modal closes when the OAuth modal is opened.
    // The onClose call is placed within an interval function to introduce a slight delay.
    // This delay provides a smoother user experience by preventing both modals from closing simultaneously.
    onClose && setTimeout(() => onClose(), 500)

    const checkWindow = setInterval(function () {
        if (oauthWindow.closed) {
            clearInterval(checkWindow)
            window.location.href = onCloseUrl
        }
    }, 500)
}

export const openBlankOAuthWindow = () => {
    const { width, height, heightOffset } = popUpConfig
    const left = (window.screen.width - width) / 2
    const top = (window.screen.height - height) / 2 - heightOffset

    const oauthWindow = window.open(
        'about:blank',
        '_blank', // 'oauthWindow
        `width=${width},height=${height},top=${top},left=${left}`
    )

    oauthWindow.document.write('<p>Loading...</p>')

    return oauthWindow
}

type OauthWindowRedirectProps = {
    oAuthUrl: string
    oauthWindow: Window
    onClose: () => void
    onCloseUrl: string
}

const redirectOauthWindow = ({
    oAuthUrl,
    oauthWindow,
    onClose,
    onCloseUrl,
}: OauthWindowRedirectProps) => {
    oauthWindow.location.href = oAuthUrl
    observeWindowClose({ onClose, oauthWindow, onCloseUrl })
}

type OpenOAuthWindowProps = {
    event: React.MouseEvent
    oAuthUrl: string
    onClose: () => void
    onCloseUrl: string
}

const openOAuthWindow = ({
    event,
    oAuthUrl,
    onClose,
    onCloseUrl,
}: OpenOAuthWindowProps) => {
    event.preventDefault()
    event.stopPropagation()

    const { width, height, heightOffset } = popUpConfig
    const left = (window.screen.width - width) / 2
    const top = (window.screen.height - height) / 2 - heightOffset

    const oauthWindow = window.open(
        oAuthUrl,
        'oauthWindow',
        `width=${width},height=${height},top=${top},left=${left}`
    )

    if (!oauthWindow) {
        alert(
            'Failed to open the Authentification form. Please contact us for help.'
        )
        return
    }

    observeWindowClose({ onClose, oauthWindow, onCloseUrl })
}
