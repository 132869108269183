//
import React from 'react'
import Toggle from 'components/ui/Toggle'
import { useSelector, useDispatch } from 'react-redux'
import { toggleFeature, clearFlashMessages } from 'reducers/modules/app'
import { upgradePlanURL } from 'utils/urls'
import FlashMessage from './FlashMessage'

/**
 * RequiresFeatures Properties
 */

export default function RequiresFeature({
    onContinue,
    onCancel,
    feature,
    children,
    logo,
}) {
    const [canEnable, features] = useCanInstallAndFeatures(feature)
    const dispatch = useDispatch()
    const { messages = [] } = useSelector(({ app }) => app)
    let isFeatureEnabled = features && features.includes(feature)

    if (!canEnable) {
        return (
            <>
                <h1>Feature required...</h1>
                <OverLimit
                    logo={logo}
                    feature={feature}
                    onCancel={(e) => onCancel({ e, isFeatureEnabled })}
                >
                    {children}
                </OverLimit>
            </>
        )
    } else {
        const onToggleFeature = () => {
            dispatch(
                toggleFeature(feature, {
                    params: { context: 'carrier-required-feature' },
                })
            )
        }

        const onClearFlashMessages = () => {
            dispatch(clearFlashMessages())
        }

        return (
            <>
                <h1>Feature required...</h1>
                {logo && (
                    <div className="c-message">
                        <div className="c-named">
                            <div className="carrier-selected carrier-name-reTransFreight c-type-live">
                                <img
                                    src={logo}
                                    title={`${feature}-title`}
                                    alt={`${feature}-title`}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="reqs-list">
                    <div className="mb-6">
                        <Toggle
                            testId={`toggle-${feature}`}
                            value={!!isFeatureEnabled}
                            onToggle={onToggleFeature}
                            disabled={false}
                        />
                    </div>
                    {children}
                </div>

                {messages.map((message, index) => (
                    <FlashMessage
                        key={index}
                        index={index}
                        message={message}
                        onDismiss={onClearFlashMessages}
                    />
                ))}

                <div>
                    <button
                        className="btn btn-primary mb-2"
                        onClick={(e) => onContinue({ e, isFeatureEnabled })}
                        type={'button'}
                        disabled={!isFeatureEnabled}
                    >
                        Continue
                    </button>
                    {onCancel && (
                        <a
                            data-testid="cancelRequiresFeatureModal"
                            href="#"
                            className="sub-action mb-0"
                            onClick={(e) => {
                                e.preventDefault()
                                onCancel({ e, isFeatureEnabled })
                                return false
                            }}
                        >
                            Cancel
                        </a>
                    )}
                </div>
            </>
        )
    }
}

function OverLimit({ children, feature, logo, onCancel }) {
    return (
        <>
            {logo && (
                <div className="c-message">
                    <div className="c-named">
                        <div className="carrier-selected carrier-name-reTransFreight c-type-live">
                            <img
                                src={logo}
                                title={`${feature}-title`}
                                alt={`${feature}-title`}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="reqs-list">
                {children}
                <p>
                    <Toggle
                        testId={`toggle-${feature}`}
                        value={false}
                        onToggle={() => {}}
                        disabled={true}
                    />
                </p>
            </div>
            <UpgradePlanFeaturesFooter />
            {onCancel && (
                <a href="#" className="sub-action" onClick={onCancel}>
                    Cancel
                </a>
            )}
        </>
    )
}

const UpgradePlanFeaturesFooter = () => (
    <>
        <div className="warn-msg full-width">
            <p>
                <i className="fa fa-exclamation-triangle ico-caution" />
            </p>
            You have met your plan's limit for enabling more features.
        </div>

        <a href={upgradePlanURL()} className="btn btn-primary">
            Upgrade Your Plan
        </a>
    </>
)

function useCanInstallAndFeatures(feature) {
    return useSelector(
        ({ app: { enabled_features = [], features_limit, plan_id } }) => [
            canEnable(enabled_features, plan_id, features_limit, feature),
            enabled_features,
        ]
    )
}

function canEnable(enabled_features, plan_id, features_limit, feature) {
    return (
        plan_id === 4 ||
        enabled_features.filter((item) => item !== feature).length <
            features_limit
    )
}
