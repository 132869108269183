import { useSelector } from 'react-redux'
import React, { useContext, useEffect, useState } from 'react'
import { findCarrierTitle } from 'components/dashboard/integrations/utils'
import { useCarrierManagement } from '../../carrierManagement'
import { StepNavigatorContext } from '../../../../ui/StepNavigator'
import { accountFormAndCanInstallSelector } from '../../containers/CarrierWizard/components/selectors'
import { EnterCredentialsNote } from '../../containers/CarrierWizard/components/WizardHeader'
import {
    CustomAccountRequirements,
    StepContainer,
    SubmitButton,
} from '../listRatesService/styles'
import NoDefaultOriginWarning from '../../containers/CarrierWizard/components/NoDefaultOriginWarning'
import useLoader from '../../../scopes/hooks/useLoader'
import UpgradePlanFooter from '../../containers/CarrierWizard/components/UpgradePlanFooter'
import { useIsCarrierCreated } from '../../../carriers/useIsCarrierCreated'
import { useOriginsList } from 'components/dashboard/hooks/useOriginsList'
import { CarrierLogoNew } from './styles'

export const InstallAccountRates = () => {
    const {
        carrier,
        state: { carrierName, carrierExists },
    } = useCarrierManagement()
    const loader = useLoader()

    const { nextStep } = useContext(StepNavigatorContext)
    const { accountForm } = useSelector(accountFormAndCanInstallSelector)

    const carrierTitle = findCarrierTitle(carrierName)
    const origins = useOriginsList()
    const storeState = useSelector(accountFormAndCanInstallSelector)
    const wasCarrierCreated = useIsCarrierCreated()

    const calculateCanInstall = () =>
        storeState.canInstall || wasCarrierCreated || carrierExists
    const [canInstall, setCanInstall] = useState(calculateCanInstall())

    useEffect(() => {
        setCanInstall(calculateCanInstall())
    }, [carrierExists])

    if (!origins || !origins.length) {
        return <NoDefaultOriginWarning />
    }

    if (loader.loading) {
        return loader.Icon()
    }

    return (
        <StepContainer>
            <h3>Installing {carrierTitle}</h3>

            <CarrierLogoNew carrier={carrier} />

            <CustomAccountRequirements withTopGap>
                <EnterCredentialsNote />
                <ul
                    dangerouslySetInnerHTML={{
                        __html: accountForm?.instructions || 'Loading...',
                    }}
                />
                <hr />
                {accountForm?.help_doc_url && (
                    <a
                        href={accountForm.help_doc_url}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="help-txt"
                        data-testid="help-doc-link"
                    >
                        How to obtain {carrierName} credentials
                    </a>
                )}
            </CustomAccountRequirements>

            {canInstall && (
                <SubmitButton onClick={nextStep} className="btn btn-primary">
                    Enter account information
                </SubmitButton>
            )}
            {!canInstall && <UpgradePlanFooter />}
        </StepContainer>
    )
}
