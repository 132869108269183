//
import React, { useContext } from 'react'
import { StepNavigatorContext } from 'components/ui/StepNavigator'

/**
 * ContinueButton Properties
 */

export default function BackButton({ onClick }) {
    const { prevStep } = useStepNavigator()
    return <a id="back" className="back" onClick={onClick ?? prevStep} />
}

function useStepNavigator() {
    return useContext(StepNavigatorContext)
}
