//
import React, { useMemo } from 'react'
import { Box, Heading, Text } from 'styles'
import { useStepNavigator } from 'components/ui/StepNavigator'
import logger from 'utils/logger'
import useNotifySupport from 'components/dashboard/integrations/hooks/useNotifySupport'
import { useModal } from 'components/ui/Modal'
import StepIndicator from 'components/dashboard/integrations/containers/CarrierWizard/components/StepIndicator'
import UpgradePlanFooter from './UpgradePlanFooter'
import styled from 'styled-components'

const FontAwesomeIcon = styled.i`
    font-size: 24px;
    margin-bottom: 16px;
`

function SupportNotified() {
    const { hide } = useModal()

    return (
        <Box>
            <Heading level={3}>Error Installing UPS&reg;</Heading>
            <Box mb={8} className="info-msg max-w500">
                <FontAwesomeIcon className="fa fa-paper-plane" />
                <Text>
                    Support has been notified about your issue. We will be in
                    touch as soon as possible to help you get this carrier setup
                    and receiving live rates.
                </Text>
            </Box>
            <button type="button" className="btn btn-primary" onClick={hide}>
                Ok, Thanks
            </button>
        </Box>
    )
}

const TryAgainButton = styled.button`
    font-size: 14px;
    display: block;
    border: none;
    background: none;
    color: ${({ theme }) => theme.colors.greybluelt1};
    margin: 0 auto;

    &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.dkblue2};
    }
`

/**
 * Renders action buttons when error occurs
 * @param id
 * @param notify
 * @returns {JSX.Element}
 * @constructor
 */
function ErrorActions({ id, notify }) {
    const stepNavigator = useStepNavigator()
    const handleNotifyClick = async () => {
        try {
            await notify.mutateAsync({ id })
        } catch (e) {
            logger.error(e)
        }
    }

    const handleTryAgainClick = () => {
        stepNavigator.navigateTo(0)
    }

    return (
        <>
            <button
                type="button"
                className="btn btn-primary"
                disabled={notify.isLoading}
                onClick={handleNotifyClick}
            >
                Notify Support
            </button>

            <TryAgainButton
                type="button"
                disabled={notify.isLoading}
                onClick={handleTryAgainClick}
            >
                Try account information again
            </TryAgainButton>
        </>
    )
}

const ListRatesErrorBox = styled.div`
    max-width: 466px;
    margin: 0 auto;
`

const Notice = styled.div`
    background: ${({ theme }) => theme.colors.yellow};
    padding: 33px 24px;
    border: 1px solid ${({ theme }) => theme.colors.yellowdk};
    border-radius: 3px;
    margin: 40px auto;
`

const NoticeTitle = styled.h3`
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700;
`

const NoticeText = styled.p`
    font-size: 14px;
    line-height: 1.71;
    margin: 20px 0;
`

const NoticeLink = styled.a`
    color: ${({ theme }) => theme.colors.greybluelt1};
`

export default function ListRatesFormError({ errors, carrier }) {
    const error = useMemo(
        () => (Array.isArray(errors) ? errors.join(', ') : errors),
        [errors]
    )

    const isOverLimit = useMemo(
        () => error?.toLowerCase()?.includes('plan limit'),
        [error]
    )
    const notify = useNotifySupport()

    if (notify.isSuccess) {
        // notify support request was sent successfully?
        return <SupportNotified />
    }

    return (
        <ListRatesErrorBox>
            <Heading level={2} m={0}>
                UPS&reg; account cannot be validated
            </Heading>
            <Notice>
                <StepIndicator total={1} current={1} warning />
                <NoticeTitle>
                    Account rates cannot be displayed. You will continue
                    displaying published daily rates until your account is
                    validated.
                </NoticeTitle>
                <NoticeText>{error}</NoticeText>
                <NoticeLink
                    href="https://docs.shipperhq.com/ups-carrier-setup/"
                    target="_blank"
                >
                    Troubleshooting UPS&reg; Validation Issues
                </NoticeLink>
            </Notice>

            {isOverLimit ? (
                <UpgradePlanFooter />
            ) : (
                <ErrorActions id={carrier.id} notify={notify} />
            )}
        </ListRatesErrorBox>
    )
}
