import { useSelector } from 'react-redux'

export function useLastName() {
    return useSelector<ReduxRootState>(
        ({
            app: {
                current_user: { last_name },
            },
        }) => last_name
    )
}
