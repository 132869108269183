import React from 'react'
import styled from 'styled-components'

const SuccessModal = styled.div`
    background: ${({ theme }) => theme.colors.greenlt1};
    border: 1px solid ${({ theme }) => theme.colors.greenlt2};
    border-radius: 3px;
    margin: ${({ showImage }) =>
        showImage ? '95px auto 16px' : '35px auto 16px'};
    padding: ${({ showImage }) => (showImage ? '58px 24px 41px' : '24px')};
    position: relative;
`

const SuccessImageWrapper = styled.div`
    max-width: 124px;
    margin: 0 auto;
    position: absolute;
    bottom: calc(100% - 32px);
    left: calc(50% - 62px);

    & div {
        position: relative;
    }
`

const SuccessImage = styled.img`
    border-radius: 6px;
    width: 124px;
`

const CheckmarkWithoutImage = styled.i`
    color: ${({ theme }) => theme.colors.green1};
    font-size: 22px;
    margin-bottom: 5px;
`

const CheckmarkWithImage = styled.i`
    position: absolute;
    right: -8px;
    top: -11px;
    font-size: 24px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.green3};
`

export function ModalSuccess({
    title,
    children,
    showCheck = true,
    showImage = false,
    imagePath,
    altText,
}) {
    return (
        <SuccessModal showImage={showImage}>
            {showCheck && (
                <CheckmarkWithoutImage
                    className="fas fa-check-circle"
                    role="img"
                    aria-label="Check mark"
                />
            )}
            {showImage && (
                <SuccessImageWrapper>
                    <div>
                        <SuccessImage src={imagePath} alt={altText} />
                        <CheckmarkWithImage
                            className="fas fa-check-circle"
                            role="img"
                            aria-label="Check mark"
                        />
                    </div>
                </SuccessImageWrapper>
            )}
            {title && <h4 className="strong">{title}</h4>}
            {children && <div>{children}</div>}
        </SuccessModal>
    )
}
