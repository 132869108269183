//
import React, { Component } from 'react'

class Toggle extends Component {
    static defaultProps = {
        value: true,
        onToggle: () => {},
        disabled: false,
        enabledTitle: 'Enabled',
        disabledTitle: 'Disabled',
        featureName: '',
    }

    toggle = () => {
        if (!this.props.disabled) {
            this.props.onToggle()
        }
    }

    render() {
        const { value, disabled, enabledTitle, disabledTitle, featureName } = this.props

        let wrapperClass = 'toggle btn'
        if (!value) {
            wrapperClass += ' btn-default off'
        } else {
            wrapperClass += ' btn-success'
        }

        if (disabled) {
            wrapperClass += ' disabled'
        }

        const labelClassName = value ? 'btn toggle-on' : 'btn active toggle-off'

        return (
            <div
                className={wrapperClass}
                data-toggle="toggle"
                onClick={this.toggle}
                data-testid="toggle"
            >
                <input
                    type="checkbox"
                    data-onstyle="success"
                    data-size="small"
                    id="tgl-4"
                    defaultChecked
                    aria-labelledby="toggle-label"
                />
                <div className="toggle-group">
                    <label data-testid={this.props.testId} id="toggle-label" className={`${labelClassName} ${featureName}`}>
                        {value ? enabledTitle : disabledTitle}
                    </label>
                    <span className="toggle-handle btn btn-default" />
                </div>
            </div>
        )
    }
}

export default Toggle
