import React, { useState } from 'react'
import { findCarrierTitle } from 'components/dashboard/integrations/utils'
import { useCarrierManagement } from '../../carrierManagement'
import { DetailsHeader, MarketplaceWrapper } from './styles'
import { useSelector } from 'react-redux'
import { accountFormAndCanInstallSelector } from '../../containers/CarrierWizard/components/selectors'
import UpgradePlanFooter from '../../containers/CarrierWizard/components/UpgradePlanFooter'

export const MarketplaceDetailsCTA = ({ showModal }) => {
    const {
        state: { carrierName },
    } = useCarrierManagement()
    const carrierTitle = findCarrierTitle(carrierName)
    const onInstallClick = () => {
        showModal()
    }

    return (
        <MarketplaceWrapper>
            <DetailsHeader>
                Instantly display {carrierTitle} rates
            </DetailsHeader>
            <div>
                Install {carrierTitle} and display standard list rates to your
                customers. Once FedEx has been installed, qualified merchants
                can enter their account information to display account rates to
                their customers.
            </div>
            <button
                data-testid="agree-step-install-button"
                type="button"
                className="btn btn-primary"
                onClick={onInstallClick}
            >
                Install
            </button>
        </MarketplaceWrapper>
    )
}
