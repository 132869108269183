//
import React, { useContext } from 'react'
import WizardHeaderContext from './WizardHeaderContext'
import CredentialsHeader from 'components/dashboard/carriers/components/CredentialsHeader'
import StepIndicator from 'components/dashboard/integrations/containers/CarrierWizard/components/StepIndicator'
import BackButton from './BackButton'
import CarrierLogo from '../../../../carriers/containers/NewCarrierWizard/components/CarrierLogo'

/**
 * WizardHeader Properties
 */

export default function WizardHeader(props) {
    const {
        wizardTitle,
        carrierTitle,
        isNew,
        validationRequired,
        carrier,
        totalSteps,
        currentStep,
        withBack = false,
    } = props
    const { isInstallSuccess } = useContext(WizardHeaderContext)

    if (isInstallSuccess) {
        return (
            <>
                <CredentialsHeader {...props} />
                <CarrierLogo carrier={carrier} isLive />
            </>
        )
    }

    if (!validationRequired) {
        return (
            <>
                {isNew && <h3>Installing {carrierTitle}</h3>}
                <CarrierLogo carrier={carrier} isLive />
                <p>
                    In order for your carrier to get rates it will need to be
                    installed.
                </p>
            </>
        )
    }

    return (
        <>
            {withBack && <BackButton />}
            {wizardTitle && <h3>{wizardTitle}</h3>}
            {isNew && <h3>Installing {carrierTitle}</h3>}
            {totalSteps && currentStep && (
                <StepIndicator total={totalSteps} current={currentStep} />
            )}
            <CarrierLogo carrier={carrier} isLive />
        </>
    )
}

export function EnterCredentialsNote() {
    return (
        <p>
            <strong>
                Enter your credentials to validate this carrier and start
                getting rates.
            </strong>
            <br />
            The credentials you will need are:
        </p>
    )
}
