//
import React, { Fragment, useState, useEffect } from 'react'
import Toggle from 'components/ui/Toggle'
/* $FlowFixMe */
import IconDimensional from 'assets/images/ratesmgr/features/icon-duo-feat-dimensional.png'
import { useSelector, useDispatch } from 'react-redux'
import { toggleFeature } from 'reducers/modules/app'
import { upgradePlanURL } from 'utils/urls'

/**
 * UShipFirstStep Properties
 */

export default function UShipFirstStep(props) {
    return (
        <div className="uship-warn">
            <h3>Installing uShip</h3>
            <EnableDimShipping {...props} />
        </div>
    )
}

function EnableDimShipping(props) {
    const { onContinue, onCancel } = props
    const [canEnable, features] = useCanInstallAndFeatures()
    const dispatch = useDispatch()
    let containsDimship = features && features.includes('dimship')

    if (!canEnable) {
        return <OverLimit onCancel={onCancel} />
    } else {
        const onToggleDimship = () => {
            dispatch(toggleFeature('dimship'))
        }

        return (
            <Fragment>
                <div className="reqs-list">
                    <p>
                        <img width="80" height="80" src={IconDimensional} />
                    </p>
                    <h4 className="normal mb-5">
                        uShip validation requires the{' '}
                        <strong>Dimensional Packing</strong> feature to be
                        enabled
                    </h4>
                    <div>
                        <Toggle
                            value={!!containsDimship}
                            onToggle={onToggleDimship}
                            disabled={false}
                        />
                    </div>
                </div>
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={onContinue}
                        type={'button'}
                        disabled={!containsDimship}
                    >
                        Continue
                    </button>
                </div>
            </Fragment>
        )
    }
}

function OverLimit(props) {
    const { onCancel } = props
    return (
        <>
            <div className="reqs-list">
                <p>
                    <img width="80" height="80" src={IconDimensional} />
                </p>
                <h4 className="normal mb20">
                    uShip validation requires the{' '}
                    <strong>Dimensional Packing</strong> feature to be enabled
                </h4>
                <p>
                    <Toggle value={false} onToggle={() => {}} disabled={true} />
                </p>
            </div>
            <UpgradePlanFeaturesFooter />
            <a
                href="#"
                className="sub-action"
                onClick={(e) => {
                    e.preventDefault()
                    onCancel()
                    return false
                }}
            >
                Cancel
            </a>
        </>
    )
}

const UpgradePlanFeaturesFooter = () => (
    <>
        <div className="warn-msg full-width">
            <p>
                <i className="fa fa-exclamation-triangle ico-caution" />
            </p>
            You have met your plan's limit for enabling more features.
        </div>

        <a href={upgradePlanURL()} className="btn btn-primary">
            Upgrade Your Plan
        </a>
    </>
)

function useCanInstallAndFeatures() {
    return useSelector(
        ({ app: { enabled_features = [], features_limit, plan_id } }) => [
            canEnable(enabled_features, plan_id, features_limit),
            enabled_features,
        ]
    )
}

function canEnable(enabled_features, plan_id, features_limit) {
    return (
        plan_id === 4 ||
        enabled_features.filter((item) => item !== 'dimship').length <
            features_limit
    )
}
