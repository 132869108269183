import React, { Fragment, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { StepNavigatorContext } from 'components/ui/StepNavigator'
import StepIndicator from 'components/dashboard/integrations/containers/CarrierWizard/components/StepIndicator'
import { ModalSuccess } from 'components/ui/ModalSuccess'
import ValidationErrors from 'components/dashboard/integrations/containers/CarrierWizard/components/ValidationErrors'
import logger from 'utils/logger'
import useCarrierTitle from '../hooks/useCarrierTitle'
import ValidationActions from './ValidationActions'
import UpgradeNotice from './UpgradeNotice'

function calculateValidationState(success) {
    return success ? 'SUCCESS' : 'FAILURE'
}

const ValidationHandler = ({
    carrierEntity,
    carrier,
    success,
    carrierDisabled,
    validationErrors = {},
    transactionId = '',
    closeModal,
    renderNotify = null,
    showLicenseAgreement,
}) => {
    const [validationState, setValidationState] = useState(() =>
        calculateValidationState(success)
    )
    const stepNavigator = useContext(StepNavigatorContext)
    const accountForm = useSelector(
        ({ app: { integrations: { account_form = {} } = {} } = {} }) =>
            account_form
    )
    const carrierTitle = useCarrierTitle(carrier.name)

    // "success" is coming from state, so we need it to update
    // the validationState when success state changes
    useEffect(() => {
        setValidationState(calculateValidationState(success))
    }, [success])

    const validationComponent = {
        SUCCESS: (
            <ValidationSuccess
                carrierEntity={carrier?.id ? carrier : carrierEntity}
                carrierDisabled={carrierDisabled}
            />
        ),
        FAILURE: (
            <ValidationFailure
                validationErrors={validationErrors}
                transactionId={transactionId}
                accountForm={accountForm}
                stepNavigator={stepNavigator}
                setValidationState={setValidationState}
                carrierTitle={carrierTitle}
                carrierEntity={carrier?.id ? carrier : carrierEntity}
                showLicenseAgreement={showLicenseAgreement}
            />
        ),
        NOTIFY: (renderNotify && renderNotify()) || (
            <ValidationNotify closeModal={closeModal} />
        ),
    }[validationState]

    return (
        <Fragment>
            <h3>{carrierTitle} Account Validation</h3>
            {validationComponent}
        </Fragment>
    )
}

const ValidationSuccess = ({ carrierEntity, carrierDisabled }) => {
    return (
        <Fragment>
            <ModalSuccess title="Validation Successful!" />
            {carrierDisabled && <UpgradeNotice />}
            <ValidationActions
                carrierEntity={carrierEntity}
                carrierDisabled={carrierDisabled}
            />
        </Fragment>
    )
}

const ValidationFailure = ({
    validationErrors,
    transactionId,
    accountForm,
    stepNavigator,
    setValidationState,
    carrierEntity = {},
    carrierTitle,
}) => {
    const handleNotifyClick = () => {
        const { id } = carrierEntity
        axios
            .post('/ratesmgr/carriers/notify', {
                id,
                transaction_id: transactionId,
            })
            .then(() => {
                setValidationState('NOTIFY')
            })
            .catch((err) => {
                logger.error('Error notifying support:', err.message)
            })
    }

    const handleTryAgainClick = () => stepNavigator.navigateTo(1)

    return (
        <Fragment>
            <div className="warn-msg max-w500">
                <StepIndicator total={1} current={1} warning={true} />

                <p
                    className="strong"
                    data-testid="validation-error"
                    data-transaction-id={transactionId}
                >
                    Your credentials could not be authenticated
                </p>

                <ValidationErrors errors={validationErrors} />

                {accountForm.troubleshooting && (
                    <p>
                        <a href={accountForm.troubleshooting} target="_blank">
                            Troubleshooting {carrierTitle} Validation Issues
                        </a>
                    </p>
                )}
            </div>

            <button
                type="button"
                className="btn btn-primary"
                onClick={handleTryAgainClick}
            >
                Edit credentials and try again
            </button>
            <div>
                <button
                    className="sub-action"
                    type="button"
                    onClick={() =>
                        window.open(
                            'https://docs.shipperhq.com/troubleshooting-carrier-authentication-errors/',
                            '_blank'
                        )
                    }
                >
                    <i className="fas fa-book" /> Troubleshoot authentication
                    errors
                </button>
            </div>
        </Fragment>
    )
}

export const ValidationNotify = ({ closeModal }) => {
    return (
        <Fragment>
            <div className="info-msg max-w500">
                <i className="fa fa-paper-plane" />
                <p>
                    Support has been notified about your validation issue. We
                    will be in touch as soon as possible to help you get this
                    carrier setup and receiving live rates.
                </p>
            </div>
            <button
                type="button"
                className="btn btn-primary"
                onClick={closeModal}
            >
                Ok, Thanks
            </button>
        </Fragment>
    )
}

export default ValidationHandler
