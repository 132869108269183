//
import React, { Fragment } from 'react'
import { upgradePlanURL } from 'utils/urls'
import { connect } from 'react-redux'

/**
 * UpgradePlanFooter Properties
 */

const UpgradePlanFooter = ({ carriersLimit }) => (
    <>
        <div data-testid="upgrade-plan-footer" className="fail-msg full-width">
            <i className="fa fa-exclamation-triangle ico-warning" /> Your Plan
            allows <strong>{carriersLimit}</strong> Carriers.{' '}
            <a href={upgradePlanURL()}>Upgrade your plan</a> to install more
            carriers.
        </div>

        <a href={upgradePlanURL()} className="btn btn-primary">
            Upgrade Plan
        </a>
    </>
)

export default connect(({ app: { carriers_limit: carriersLimit } }) => ({
    carriersLimit,
}))(UpgradePlanFooter)
