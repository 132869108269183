//
import React from 'react'
import MultiStepForm from 'components/ui/form/MultiStepForm'
import FormStep from 'components/ui/form/FormStep'
import Loader from 'components/Loader'
import useCreateRegistration from '../hooks/useCreateRegistration'
import useCreateCarrier from '../hooks/useCreateCarrier'
import ListRatesFormError from './ListRatesFormError'
import ListRatesFormSuccess from './ListRatesFormSuccess'
import { UPSAgreementStep } from './UPSAgreementForm'

export default function UPSListRatesWizard({
    onAccountInfoClick,
    onClose,
    carrierId,
    updateCarrier,
}) {
    const {
        isSuccess: isCreateRegistrationSuccess,
        mutate: createRegistration,
        isLoading: isCreatingRegistration,
        error: createRegistrationError,
    } = useCreateRegistration()

    const {
        data: newCarrier,
        mutate: createCarrier,
        isLoading: isCreatingCarrier,
        isSuccess: isCreateCarrierSuccess,
        error: createCarrierError,
    } = useCreateCarrier()

    const error =
        createRegistrationError?.response?.data?.error ||
        createCarrierError?.response?.data?.error

    const handleSubmit = ({ agreement }) => {
        const carrier = {
            name: 'ups',
            id: carrierId,
            carrier_type: 'ups',
            title: 'UPS',
        }

        const createCarrierVariables = {
            carrier,
            isIndependent: true,
        }

        return new Promise((resolve, reject) => {
            createCarrier(createCarrierVariables, {
                onSuccess: (carrier) => {
                    updateCarrier(carrier)

                    const createRegistrationVariables = {
                        carrierId: carrier.id,
                    }

                    createRegistration(createRegistrationVariables, {
                        onSuccess: () => {
                            resolve()
                        },
                        onError: () => {
                            reject()
                        },
                    })
                },
                onError: () => {
                    reject()
                },
            })
        })
    }

    const handleFinishClick = () => {
        // eslint-disable-next-line no-undef
        window.location = window.Routes.edit_ratesmgr_carrier_path(
            carrierId || newCarrier.id
        )
    }

    const isLoading = isCreatingRegistration || isCreatingCarrier
    const isSuccess = isCreateCarrierSuccess && isCreateRegistrationSuccess

    return (
        <MultiStepForm
            id="ups_registration"
            trackingKey="mp-install-ups"
            onSubmit={handleSubmit}
            dontPersist={true}
            disableAutocomplete={true}
        >
            <FormStep name="agreement" last={true}>
                {(state) => (
                    <UPSAgreementStep
                        title={<span>UPS&reg; Account Validation</span>}
                        onAgree={state.handleSubmit}
                    />
                )}
            </FormStep>
            <FormStep name={isSuccess ? 'success' : 'fail'}>
                {() => {
                    if (isLoading) {
                        return (
                            <>
                                Updating carrier...
                                <Loader />
                            </>
                        )
                    }
                    // If there isn't data or there's an error
                    // then something went wrong and we should show the error
                    // message
                    return isSuccess ? (
                        <ListRatesFormSuccess
                            onAccountInfoClick={onAccountInfoClick}
                            onFinishClick={handleFinishClick}
                        />
                    ) : (
                        <ListRatesFormError
                            carrier={newCarrier}
                            errors={[error]}
                        />
                    )
                }}
            </FormStep>
        </MultiStepForm>
    )
}
