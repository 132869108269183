//
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'components/ui/Modal'
import ValidationStatusContext from 'components/dashboard/integrations/components/ValidationStatusContext'
import { findCarrierTitle } from 'components/dashboard/integrations/utils'
import { pushAppData } from 'reducers/modules/app'
import { markValidated } from 'reducers/modules/integrations'

import { UPSOauthAccountRatesWizard } from './UPSOauthAccountRatesWizard'
import UPSListRatesWizard from './UPSListRatesWizard'
import UpgradePlanFooter from './UpgradePlanFooter'

/**
 * CarrierWizard Properties
 */

/**
 * CarrierWizard State
 */

/**
 */
export class UPSWizard extends Component {
    static contextType = ValidationStatusContext

    container

    static defaultProps = {
        inline: false,
        onClose: () => undefined,
    }

    constructor(props) {
        super(props)

        this.state = {
            setAgreementData: this.props.storeUPSAgreement,
            carrierEntity: props.account ? props.account : null,
            success: false,
            carrierDisabled: false,
            installationError: false,
            errorCause: null,
            modalVisible: false,
            canInstall: props.canInstall,
            formSubmitted: false,
            shouldShowListRatesSteps: true,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.account && !state.carrierEntity?.id) {
            return {
                ...state,
                carrierEntity: props.account,
            }
        }

        return state
    }

    componentDidMount() {
        window.io_bbout_element_id = 'ratesmgr_ups_registration_device_id'
        window.io_install_stm = false
        window.io_exclude_stm = 12
        window.io_install_flash = false
        window.io_enable_rip = true

        if (this.container) {
            const scr = document.createElement('script')
            scr.type = 'text/javascript'
            scr.async = true
            scr.src = 'https://mpsnare.iesnare.com/snare.js'
            this.container && this.container.appendChild(scr)
        }

        // skip to the second step when user clicks on "View and Update Credentials"
        const { replaceCredentials } = this.context
        if (replaceCredentials) {
            this.setState({
                modalVisible: true,
            })
        }
    }

    onClose = (e, toReload = true) => {
        e && e.preventDefault()

        this.setState({ modalVisible: false })

        if (this.state.formSubmitted) {
            if (this.context.setInstalled) {
                this.context.setInstalled({
                    ...this.state.carrierEntity,
                    validated: this.state.success,
                })
            }

            if (this.state.success) {
                this.props.markValidated(
                    this.props.carrier,
                    this.state.carrierEntity
                )
            }
        }

        this.props.onClose(this.state.success)

        // eslint-disable-next-line no-undef
        toReload && window.location.reload()
    }

    openWizard = () => {
        this.setState({ modalVisible: true })
    }

    handleAccountInfoClick = () => {
        this.setState({ shouldShowListRatesSteps: false })
    }

    updateCarrier = (newCarrierEntity) => {
        const { carrierEntity: oldCarrierEntity = {} } = this.state
        const carrierEntity = { ...oldCarrierEntity, ...newCarrierEntity }

        this.setState({ carrierEntity })
    }

    render() {
        const { inline, carrier, account } = this.props

        const {
            carrierEntity,
            validationErrors,
            shouldShowListRatesSteps,
            modalVisible,
            success,
            contactSupport,
            carrierDisabled,
            canInstall,
        } = this.state

        const title = findCarrierTitle(carrier.name)

        const isInstalledChildAccount =
            !account?.owner && !!this.props.carrier?.id

        const { id: carrierId } = account || {}
        const isListRatesAccount = account?.list_rates ?? false
        const isSurePost = carrier.name.toLowerCase() === 'surepost'
        const isAccessPoint = carrier.name.toLowerCase() === 'accesspoint'
        const isGroundFreight =
            carrier.name.toLowerCase() === 'upsgroundfreight'

        const accountInitialValues = account
            ? {
                  account_number: account.shipper_number,
                  ...account.ups_account_address,
              }
            : {}

        const isShowingListRatesWizard =
            shouldShowListRatesSteps &&
            !isListRatesAccount &&
            !isSurePost &&
            !isAccessPoint &&
            !isGroundFreight

        const displayUpgradePlan = !canInstall && !carrierId

        return (
            <div ref={(el) => (this.container = el)}>
                <input type="hidden" id="ratesmgr_ups_registration_device_id" />
                {inline && (
                    <CarrierPageInstallStep onInstallClick={this.openWizard} />
                )}
                {inline && !modalVisible ? null : (
                    <Modal
                        modalName="mp-install-ups"
                        captureNotifications={false}
                        onClose={this.onClose}
                        className="modal-content install-app"
                    >
                        {displayUpgradePlan && (
                            <>
                                <h3>Installing {title}</h3>
                                <UpgradePlanFooter />
                            </>
                        )}

                        {!displayUpgradePlan && (
                            <>
                                {isShowingListRatesWizard &&
                                !isInstalledChildAccount ? (
                                    <UPSListRatesWizard
                                        onClose={this.onClose}
                                        onAccountInfoClick={
                                            this.handleAccountInfoClick
                                        }
                                        carrierId={
                                            carrier?.id || carrierId || ''
                                        }
                                        updateCarrier={this.updateCarrier}
                                    />
                                ) : (
                                    <UPSOauthAccountRatesWizard
                                        accountForm={this.props.accountForm}
                                        carrier={{
                                            ...carrierEntity,
                                            ...carrier,
                                        }}
                                        onClose={(e) => this.onClose(e, false)}
                                        account={this.props.account}
                                    />
                                )}
                            </>
                        )}
                    </Modal>
                )}
            </div>
        )
    }
}

function CarrierPageInstallStep({ onInstallClick }) {
    return (
        <div style={{ textAlign: 'left' }}>
            <h4 className="strong">Instantly display UPS&reg; rates</h4>
            <p>
                Install UPS&reg; and display published daily rates to your
                customers. Once UPS has been installed, qualified merchants can
                enter their account information to display account rates to
                their customers.
            </p>
            <button
                type="submit"
                className="btn btn-primary"
                onClick={onInstallClick}
            >
                Install
            </button>

            <hr />
            <div className="caption-sm">
                <strong>Note:</strong> This carrier allows multiple accounts.
                After validating your first account you will be able to add and
                validate additional accounts.
            </div>
        </div>
    )
}

export default connect(
    ({ app: { integrations: { can_install, account_form } = {} } = {} }) => ({
        canInstall: can_install,
        accountForm: account_form,
    }),
    {
        markValidated,
        pushAppData,
    }
)(UPSWizard)
