import React, { useState, useEffect } from 'react'

import GenericWizard from './components/GenericWizard'
import { FedexWizard } from '../../services/FedEx/FedexWizard'
import { USPSWizard } from '../../services/USPSService/USPSWizard'
import UPSWizard from './components/UPSWizard'
import { useSelector, useDispatch } from 'react-redux'
import { loadCarrierForm } from 'reducers/modules/integrations'
import { RequiredFeatureWarning } from 'components/dashboard/carriers/containers/NewCarrierWizard/components/RequiredFeatureWarning'
import { useCarrierRequiredFeatures } from 'components/dashboard/integrations/hooks/useCarrierRequiredFeatures'
import Modal from 'components/ui/Modal'
import { ConditionalWrapper } from 'components/ui/ConditionalWrapper'
import { CarrierManagementServiceProvider } from 'components/dashboard/integrations/carrierManagement'
import {
    isFedexCarrier,
    requiresUPSCarrierWizard,
    isUSPSCarrier,
} from '../../utils'
import LoaderIcon from '../../../../LoaderIcon'

function CarrierWizard(props) {
    const { carrier, onClose, inline, account, independent, displayError } =
        props

    const { skipRequiredFeature, requiredFeature, accountForm, logos } =
        useCarrierWizard(carrier)

    if (requiredFeature) {
        return (
            <ConditionalFeatureWarning
                inline={inline}
                feature={requiredFeature}
                onClose={onClose}
                carrier={carrier}
                logos={logos}
                onContinue={skipRequiredFeature}
            />
        )
    }

    if (requiresUPSCarrierWizard(carrier)) {
        return (
            <UPSWizard accountForm={accountForm} account={account} {...props} />
        )
    }

    if (isFedexCarrier(carrier)) {
        return (
            <CarrierManagementServiceProvider
                carrier={carrier}
                account={account}
                independent={independent}
                isMarketplace={inline}
            >
                <FedexWizard onClose={onClose} displayError={displayError} />
            </CarrierManagementServiceProvider>
        )
    }

    if (isUSPSCarrier(carrier)) {
        return (
            <CarrierManagementServiceProvider
                carrier={carrier}
                account={account}
                independent={independent}
                isMarketplace={inline}
            >
                <USPSWizard onClose={onClose} displayError={displayError} />
            </CarrierManagementServiceProvider>
        )
    }

    return <GenericWizard accountForm={accountForm} {...props} />
}

function useCarrierWizard(carrier) {
    const dispatch = useDispatch()
    const [requiredFeature, setRequiredFeature] = useState(null)
    const { getCarrierRequiredFeatures } = useCarrierRequiredFeatures()

    const { accountForm } = useSelector(
        ({ app: { integrations: { account_form } = {} } }) => ({
            accountForm: account_form,
        })
    )

    useEffect(() => {
        const requiredFeatures = getCarrierRequiredFeatures(carrier)

        if (requiredFeatures.length > 0) {
            setRequiredFeature(requiredFeatures[0])
        }

        dispatch(loadCarrierForm(carrier))
    }, [])

    const skipRequiredFeature = () => setRequiredFeature(null)

    return {
        requiredFeature,
        skipRequiredFeature,
        accountForm,
    }
}

function ConditionalFeatureWarning({
    inline,
    onClose,
    carrier,
    feature,
    onContinue,
}) {
    return (
        <ConditionalWrapper
            condition={!inline}
            wrapper={(children) => (
                <Modal className="modal-content install-app" onClose={onClose}>
                    {children}
                </Modal>
            )}
        >
            <>
                <h3>Installing {carrier.title}</h3>

                <RequiredFeatureWarning
                    title={carrier.title}
                    feature={feature}
                    onContinue={onContinue}
                    onCancel={inline ? undefined : onClose}
                />
            </>
        </ConditionalWrapper>
    )
}

export default function CarrierWizardLoader(props) {
    if (!props?.carrier?.name) {
        return <LoaderIcon />
    }
    return <CarrierWizard {...props} />
}
