import React, { Fragment } from 'react'
import useCarrierTitle from 'components/dashboard/integrations/containers/CarrierWizard/hooks/useCarrierTitle'
import UpgradePlanFooter from 'components/dashboard/integrations/containers/CarrierWizard/components/UpgradePlanFooter'

export default function InstallationError(props) {
    const { carrier, errorCause } = props
    const title = useCarrierTitle(carrier.name)

    return (
        <Fragment>
            <h3>Installing {title}</h3>
            <div className="validation-notice">
                <h5>Error occurred while installing carrier</h5>
                <p>
                    {errorCause && errorCause.length ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: errorCause + ' ',
                            }}
                        />
                    ) : null}
                    Please try again later or contact support.
                </p>
            </div>
            {errorCause && errorCause.includes('plan limit') ? (
                <UpgradePlanFooter />
            ) : null}
        </Fragment>
    )
}
