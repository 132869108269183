import { extractErrorCause } from 'components/dashboard/integrations/containers/CarrierWizard/errors'
import {
    installCarrier as installCarrierAction,
    markValidated,
} from 'reducers/modules/integrations'
import { useDispatch, useSelector } from 'react-redux'
import React, { useReducer, createContext, useContext, useEffect } from 'react'
import { availableIntegrationsSelector } from 'components/dashboard/integrations/containers/CarrierWizard/components/selectors'
import { stripSpacesFromFormData } from 'components/dashboard/integrations/containers/CarrierWizard/components/forms'
import { axiosClient } from 'net/ajax'
import logger from 'utils/logger'

import { pushAppData } from 'reducers/modules/app'
import { Dispatch } from 'redux'

export const FEDEX_RATE_TYPES = {
    userNegotiated: 'user-negotiated',
    standard: 'standard',
}

export type CarrierManagementContext = {
    account?: any
    displayError?: any
    independent?: boolean
    carrier?: any
    carrierEntity?: any
    carrierName?: string
    errorCause?: string
    installationError?: boolean
    installed?: boolean
    isMarketplace?: boolean
    validationErrors?: any
    carrierExists?: boolean
    dispatch?: (action: CarrierServiceAction) => void
    dispatchRedux?: Dispatch<any>
    state?: {
        carrierName?: string
        carrierEntity?: any
        selectedOrigin?: any
        carrier?: any
        carrierExists?: boolean
        fedexRateType?: any
        fedexRateTypeSelected?: boolean
        installationError?: any
        validationErrors?: any
        carrierDisabled?: boolean
        validationSuccess?: boolean
        transactionId?: string
    }
}

const initialState: CarrierManagementContext = {
    carrierEntity: null,
    carrierName: 'null',
    errorCause: '',
    installationError: false,
    installed: false,
    validationErrors: null,
    carrierExists: false,
}

export const CarrierManagementServiceContext =
    createContext<CarrierManagementContext>({
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        redux: () => {},
        // @ts-ignore
        dispatchRedux: () => {},
    })

type CarrierServiceAction = {
    type:
        | 'UPDATE_STATE'
        | 'integrations/INSTALL_SUCCESS'
        | 'integrations/INSTALL_FAIL'
    account?: unknown
    carrier?: unknown
    carrierName?: string
    carrierExists?: boolean
    carrierEntity?: unknown
    payload?: {
        data: any
    }
    error?: {
        response?: {
            data?: any
        }
    }
}

function carrierServiceReducer(
    state: CarrierManagementContext,
    action: CarrierServiceAction
) {
    switch (action.type) {
        case 'UPDATE_STATE': {
            const { type, ...rest } = action
            return {
                ...state,
                ...rest,
            }
        }

        // carrier was created successfully?
        case 'integrations/INSTALL_SUCCESS':
            return {
                ...state,
                carrierEntity: action.payload.data,
                installationError: false,
                errorCause: '',
                installed: !!action.payload,
            }

        case 'integrations/INSTALL_FAIL':
            return {
                ...state,
                installationError: true,
                installed: false,
                /* $FlowFixMe */
                errorCause: extractErrorCause(state?.carrier, action),
                validationErrors: action?.error?.response?.data?.errors,
            }

        default:
            return state
    }
}

export function CarrierManagementServiceProvider({
    children,
    state: stateOverride = {},
    ...rest
}) {
    const dispatchRedux = useDispatch()
    const integrations = useSelector(availableIntegrationsSelector)

    const { account, carrier } = rest

    /* $FlowFixMe */
    const [state, dispatch] = useReducer(carrierServiceReducer, {}, () => ({
        ...initialState,
        ...stateOverride,
    }))

    // handle account updates
    useEffect(() => {
        const action: CarrierServiceAction = { type: 'UPDATE_STATE', account }
        const { carrierEntity } = state

        if (account?.id && (!carrierEntity || !carrierEntity.id)) {
            action.carrierEntity = account
            action.carrierExists = true
        }

        dispatch(action)
    }, [account])

    // handle carrier updates
    useEffect(() => {
        const name = carrier?.name || ''

        const selectedCarrier =
            integrations &&
            integrations.find((carrier) => carrier.name === name)

        const carrierName = selectedCarrier ? selectedCarrier.title : name

        dispatch({ type: 'UPDATE_STATE', carrier, carrierName })
    }, [carrier])

    return (
        <CarrierManagementServiceContext.Provider
            value={{ state, dispatch, dispatchRedux, ...rest }}
        >
            {children}
        </CarrierManagementServiceContext.Provider>
    )
}

export function useCarrierManagement() {
    return useContext(CarrierManagementServiceContext)
}

/**
 * Installs the carrier
 * @param context
 * @param options
 * @return {Promise<void>}
 */
export async function installCarrier(
    context: CarrierManagementContext,
    options = {}
) {
    const {
        carrier = {},
        account = {},
        independent = false,
        state: { carrierName = 'untitled' } = {},
        dispatch,
        dispatchRedux,
    } = context

    const carrierData = {
        name: carrier.name,
        title: carrier.title || account?.title || carrierName,
        id: account?.id,
    }

    // this actually returns a promise and we need to wait for result
    const result = await dispatchRedux(
        installCarrierAction(carrierData, independent, options)
    )

    // result.action will be integrations/INSTALL_SUCCESS or integrations/INSTALL_FAIL here
    dispatch(result as unknown as CarrierServiceAction)

    return result
}

/**
 * Updates carrier wizard state
 * @param context
 * @param newState
 */
export function updateState(context, newState) {
    const { dispatch } = context

    dispatch({
        type: 'UPDATE_STATE',
        ...newState,
    })
}

export function updateFedexRateType(context, fedexRateType) {
    updateState(context, {
        fedexRateType,
    })
}

export async function postRegistrationData(
    context: CarrierManagementContext,
    data
) {
    const {
        displayError,
        carrier,
        state: { carrierEntity, selectedOrigin } = {},
    } = context

    const id = carrier?.id || carrierEntity.id

    // setFormSubmitted(true)
    if (!id) {
        const result = await installCarrier(context, {
            ...stripSpacesFromFormData(data), // force validation
            active: 0,
            invalid_credentials: 1,
            origin_ids: [selectedOrigin],
            list_rates: false,
        })

        return result
    }

    const request = {
        ratesmgr_carrier: {
            ...stripSpacesFromFormData(data), // force validation
            active: 0,
            invalid_credentials: 1,
            origin_ids: [selectedOrigin],
            list_rates: false,
        },
    }

    try {
        const result = await axiosClient.put(
            `/ratesmgr/carriers/${id}/install`,
            request
        )

        if (result?.status === 200) {
            return result.data
        }

        if (displayError) {
            displayError(`Unexpected server error occured`)
        }

        return 1
    } catch (e) {
        console.error(e)
        if (!e.response) {
            throw e
        }

        logger.error('error posting registration data:', e.response.data)

        if (displayError) {
            displayError(e.response.data.error)
        }

        return e.response.data
    }
}

export async function postNotifyCarrierFailure(
    context: CarrierManagementContext,
    loader
) {
    const { state: { carrierEntity: { id } = {} } = {} } = context

    loader.start()

    try {
        const result = await axiosClient.post('/ratesmgr/carriers/notify', {
            id,
        })

        logger.info('notified support!')
    } catch (e) {
        logger.error('error notifying support:', e.message)
    }
    loader.stop()
}

export async function submitCarrierData(
    context: CarrierManagementContext,
    data
) {
    const { dispatchRedux, carrier, state: { carrierEntity } = {} } = context

    const result = await postRegistrationData(context, data.account_info)

    if (typeof result !== 'number') {
        if (result.installed) {
            updateState(context, {
                carrierDisabled: !result.can_activate,
                validated: true,
                validationSuccess: true,
                validationErrors: result.errors,
                contactSupport: null,
                installed: true,
                carrierEntity: {
                    ...carrierEntity,
                    ...data.account_info,
                },
            })

            dispatchRedux(markValidated(carrier, carrierEntity))

            if (result.validation_hint) {
                dispatchRedux(
                    pushAppData({
                        validation_hint: result.validation_hint,
                    })
                )
            }
        } else {
            updateState(context, {
                validated: false,
                carrierDisabled: false,
                validationSuccess: false,
                validationErrors: result.errors,
                contactSupport: result.contact_support,
                transactionId: result.transaction_id,
            })
        }
    } else {
        // if (displayError) {
        //     displayError(
        //         `Failed to validate carrier, please recheck your credentials`
        //     )
        // }

        updateState(context, {
            validated: false,
            carrierDisabled: false,
            validationSuccess: false,
        })
    }
}

async function sendFedexRegistrationRequest(
    context: CarrierManagementContext,
    data
) {
    const { displayError } = context

    try {
        const registration = { shippingAddress: {}, userAddress: {} }
        for (const [k, v] of Object.entries(data.registration)) {
            if (k.indexOf('address_') === 0) {
                registration.shippingAddress[k.substr('address_'.length)] = v
                registration.userAddress[k.substr('address_'.length)] = v
            } else {
                registration[k] = v
            }
        }

        const result = await axiosClient.post(
            `/ratesmgr/fedex_registrations/install`,
            { ...data, registration }
        )

        if (result?.status === 200) {
            return result.data
        }

        return 1
    } catch (e) {
        if (!e.response) {
            throw e
        }

        logger.error('error posting Fedex registration data:', e.response.data)

        if (displayError) {
            displayError(e.response.data.error)
        }

        return e.response.data
    }
}

export async function submitFedexRegistrationData(
    context: CarrierManagementContext,
    data
) {
    const {
        dispatchRedux,
        carrier,
        state: { carrierEntity, selectedOrigin } = {},
    } = context

    let id = carrier?.id || carrierEntity?.id

    if (!id) {
        const result = await installCarrier(context, {
            ...stripSpacesFromFormData(data), // force validation
            active: 0,
            invalid_credentials: 1,
            origin_ids: [selectedOrigin],
            list_rates: false,
        })

        id = result?.payload?.data?.id
    }

    const response = await sendFedexRegistrationRequest(context, {
        id,
        registration: data.account_info,
    })

    if (typeof response !== 'number') {
        if (response.ok) {
            updateState(context, {
                carrierDisabled: !response.can_activate,
                validated: true,
                validationSuccess: true,
                validationErrors: response.errors,
                contactSupport: null,
                installed: true,
            })

            dispatchRedux(markValidated(carrier, carrierEntity))

            if (response.validation_hint) {
                dispatchRedux(
                    pushAppData({
                        validation_hint: response.validation_hint,
                    })
                )
            }
        } else {
            updateState(context, {
                validated: false,
                carrierDisabled: false,
                validationSuccess: false,
                validationErrors: response.errors,
                contactSupport: response.contact_support,
                transactionId: response.transaction_id,
            })
        }
    } else {
        updateState(context, {
            validated: false,
            carrierDisabled: false,
            validationSuccess: false,
        })
    }
}

export async function submitCarrierRegistrationData(
    context: CarrierManagementContext,
    data
) {
    const { carrier, state: { carrierEntity, selectedOrigin } = {} } = context

    let id = carrier?.id || carrierEntity?.id

    if (!id) {
        const result = await installCarrier(context, {
            ...stripSpacesFromFormData(data), // force validation
            active: 0,
            invalid_credentials: 0,
            origin_ids: [selectedOrigin],
            list_rates: true,
        })

        id = result?.payload?.data?.id
    } else {
        await submitCarrierData(context, data)
    }
}

/**
 * Updates selected origin in the carrier form
 * @param context
 * @param selectedOrigin
 */
export function selectOrigin(context, selectedOrigin) {
    updateState(context, { selectedOrigin })
}
