//
import React, { useEffect, useRef } from 'react'

/**
 * OriginSelector Properties
 */

const OriginSelector = ({
    origins,
    onSelect,
    className,
    selected,
    carrierId,
}) => {
    const selectRef = useRef()
    useEffect(() => {
        if (selectRef.current) {
            onSelect(parseInt(selectRef.current.value))
        }
    }, [])

    // If we add anything new which only has one column for carrier validation fields, adding it to this list will
    // cause the Origin Selector to display with matching width.
    const onecolumn = /(loqate|delivCo|rlFreight|experian)$/

    if (!onecolumn.test(carrierId)) {
        return origins && origins.length > 1 ? (
            <div className="origin-selector col-md-12 xsmini-col">
                <div className={className ? className + ' ' : ''}>
                    <div className="form-group">
                        <label>Origin associated with account</label>
                        <select
                            ref={selectRef}
                            className="form-control select optional"
                            onChange={(e) => {
                                onSelect(parseInt(e.currentTarget.value))
                            }}
                            defaultValue={
                                selected && selected.length
                                    ? selected[0]
                                    : undefined
                            }
                        >
                            {origins.map((origin) => (
                                <option key={origin.value} value={origin.value}>
                                    {origin.title}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        ) : null
    } else {
        return origins && origins.length > 1 ? (
            <div className="origin-selector form-group col-md-12 xsmini-col">
                <div
                    className={(className ? className + ' ' : '') + 'row'}
                    style={{ width: '49%', margin: '0 auto 15px' }}
                >
                    <div className="form-group">
                        <label>Origin associated with account</label>
                        <select
                            ref={selectRef}
                            className="form-control select optional"
                            onChange={(e) => {
                                onSelect(parseInt(e.currentTarget.value))
                            }}
                            defaultValue={
                                selected && selected.length
                                    ? selected[0]
                                    : undefined
                            }
                        >
                            {origins.map((origin) => (
                                <option key={origin.value} value={origin.value}>
                                    {origin.title}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default OriginSelector
