import { useContext } from 'react'
import WizardHeaderContext from '../integrations/containers/CarrierWizard/components/WizardHeaderContext'

/**
 * Returns true if the carrier was created successfully in add new carrier flow
 * @returns {boolean | undefined}
 */
export function useIsCarrierCreated() {
    const context = useContext(WizardHeaderContext)
    return context?.isInstallSuccess
}
