import React, { useState } from 'react'
import { UPSOauthForm } from './UPSOauthForm'

import MultiStepForm from 'components/ui/form/MultiStepForm'
import FormStep from 'components/ui/form/FormStep'
import { useUpdateCarrierCredentials } from 'components/dashboard/integrations/containers/CarrierWizard/hooks/useUpdateCarrier'
import { AccountInfoStep } from './UPSAccountRatesWizard'
import { UPSAgreementStep } from './UPSAgreementForm'
import { CarrierCredentialsDetails } from './GenericWizard'
import { findCarrierTitle } from 'components/dashboard/integrations/utils'

interface UPSOauthAccountRatesWizardProps {
    accountForm: {
        multiple_accounts: boolean
    }
    carrier: {
        id: string
        name: string
        shipper_number: string
        validated: boolean
        list_rates: boolean
    }
    onClose: () => void
    account: unknown
}

export const UPSOauthAccountRatesWizard: React.FC<
    UPSOauthAccountRatesWizardProps
> = ({ accountForm, carrier, onClose, account }) => {
    const [disabled, setDisabled] = useState(false)

    const updateMutation = useUpdateCarrierCredentials()

    const updateShippingAccount = (handleSubmit, values) => () => {
        if (disabled) return
        if (updateMutation.isLoading) return

        setDisabled(true)

        const mutationValues = { shipper_number: values.account_number }

        return updateMutation.mutate(
            { carrier, values: mutationValues },
            {
                onSuccess: () => {
                    setDisabled(false)
                    handleSubmit()
                },
                onError: () => {
                    setDisabled(false)
                },
            }
        )
    }

    const skipAgreementStep =
        carrier.list_rates || carrier.validated || carrier.shipper_number

    const carrierTitle = carrier?.name ? findCarrierTitle(carrier.name) : 'UPS'
    const title = `${carrierTitle} Account Validation`

    const offset = skipAgreementStep ? 1 : 0
    const totalSteps = 3 - offset

    const steps = {
        agreement: 1 - offset,
        account_info_ups: 2 - offset,
        ups_oauth: 3 - offset,
    }

    return (
        <MultiStepForm
            id="ups_registration"
            trackingKey="mp-install-ups"
            onSubmit={() => {}}
            dontPersist={true}
            disableAutocomplete={true}
        >
            {!skipAgreementStep && (
                <FormStep name="agreement" last={true}>
                    {(state) => (
                        <UPSAgreementStep
                            totalSteps={totalSteps}
                            currentStep={steps.agreement}
                            title={<span>UPS&reg; Account Validation</span>}
                            onAgree={state.handleSubmit}
                        />
                    )}
                </FormStep>
            )}
            <FormStep
                name="account_info_ups"
                className="account-info"
                initialValues={{ account_number: carrier.shipper_number }}
                onSubmit={updateShippingAccount}
            >
                {({ handleSubmit, hasValidationErrors, values }) => (
                    <AccountInfoStep
                        title={title}
                        disabled={disabled}
                        onContinueClick={updateShippingAccount(
                            handleSubmit,
                            values
                        )}
                        hasValidationErrors={hasValidationErrors}
                        withInvoiceField={false}
                        totalSteps={totalSteps}
                        currentStep={steps.account_info_ups}
                    />
                )}
            </FormStep>

            <FormStep name="ups_oauth" last={true} onSubmit={() => {}}>
                {() => (
                    <UPSOauthStep
                        title={title}
                        accountForm={accountForm}
                        carrier={carrier}
                        onClose={onClose}
                        account={account}
                        totalSteps={totalSteps}
                        currentStep={steps.ups_oauth}
                    />
                )}
            </FormStep>
        </MultiStepForm>
    )
}

const UPSOauthStep = ({
    title,
    accountForm,
    carrier,
    onClose,
    account,
    totalSteps,
    currentStep,
}) => {
    return (
        <>
            <CarrierCredentialsDetails
                accountForm={accountForm}
                carrier={carrier}
                isNew={false}
                totalSteps={totalSteps}
                currentStep={currentStep}
                withBack
                wizardTitle={title}
            />

            <UPSOauthForm
                accountForm={accountForm}
                carrier={carrier}
                onClose={onClose}
                account={account}
            />
        </>
    )
}
