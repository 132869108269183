import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import {
    accountFormSelector,
    userSettings as userSettingsSelector,
} from '../../containers/CarrierWizard/components/selectors'
import useOptionSources from 'hooks/useOptionSources'
import resolveFields from 'components/ui/form/fieldResolver'
import FormStep from 'components/ui/form/FormStep'
import DynamicForm from 'components/ui/form/DynamicForm'
import ContinueButton from '../../containers/CarrierWizard/components/ContinueButton'
import useCarrierTitle from '../../containers/CarrierWizard/hooks/useCarrierTitle'
import { useCarrierManagement } from '../../carrierManagement'
import { usePreselectDefaultOrigin } from './hooks/common'

export const ValidationStep2 = () => {
    const carrierForm = useSelector(accountFormSelector)
    if (carrierForm.fields) {
        return <ValidationStep2Loaded />
    }

    return <div>Loading...</div>
}

function ValidationStep2Loaded() {
    const optionSources = useOptionSources()
    const userSettings = useSelector(userSettingsSelector)
    const carrierForm = useSelector(accountFormSelector)
    const [mergedForm] = useState(() => carrierForm)
    const context = useCarrierManagement()
    const {
        state: { carrierName },
    } = context

    usePreselectDefaultOrigin()

    const carrierTitle = useCarrierTitle(carrierName)

    // Calculate initial form field values
    const [initialValues] = useState(() => {
        const result = {}

        // flatten fields list
        const fields = Object.values(resolveFields(mergedForm))

        const reducer = (_, field) => {
            if (field.initial_value) {
                result[field.name] = field.initial_value
            }

            if (Array.isArray(field.fields)) {
                field.fields.reduce(reducer, {})
            }
        }

        fields.reduce(reducer, {})

        return result
    })

    return (
        <FormStep
            initialValues={initialValues}
            name="account_info"
            last={true}
            key="account"
        >
            {({ values, handleSubmit, hasValidationErrors }) => {
                return (
                    <div data-testid="validation2">
                        <h3>{carrierTitle} Account Validation</h3>
                        <div className="account-info-form compact-form">
                            <DynamicForm
                                tab={mergedForm}
                                values={values}
                                optionSources={optionSources}
                                userSettings={userSettings}
                                forceRowStyle="col-md-6 xsmini-col"
                                noAutocomplete
                            />
                        </div>
                        <ContinueButton
                            errors={hasValidationErrors}
                            onClick={handleSubmit}
                            label="Validate"
                        />
                    </div>
                )
            }}
        </FormStep>
    )
}
