import React from 'react'
import StepIndicator from 'components/dashboard/integrations/containers/CarrierWizard/components/StepIndicator'
import ContinueButton from './ContinueButton'
import BackButton from './BackButton'
import BottomNotes from './BottomNotes'
import AccountInfoFormFields from './AccountInfoFormFields'
import styles from './UPSAccountWizard.module.css'

export function AccountInfoStep(props) {
    const {
        title,
        hasValidationErrors,
        onContinueClick,
        withInvoiceField = true,
        totalSteps = TOTAL_STEPS,
        currentStep = 2,
        disabled = false,
    } = props
    return (
        <>
            <BackButton />
            {title ? <h3>{title}</h3> : <h3>UPS&reg; Validation</h3>}
            <StepIndicator total={totalSteps} current={currentStep} />

            <div className={styles.helpBox}>
                <p className={styles.helpBoxText}>Please ensure the UPS account information entered below is associated to an <a href="https://docs.shipperhq.com/ups-carrier-setup/#Required_Information" target='_blank' className={styles.helpBoxLink}>active UPS.com account</a></p>
            </div>

            <h4>UPS Account Information</h4>
            <AccountInfoFormFields withInvoiceField={withInvoiceField} />
            <ContinueButton
                disabled={disabled}
                errors={hasValidationErrors}
                onClick={onContinueClick}
            />

            <BottomNotes />
        </>
    )
}
