//
import React from 'react'
import { Text } from 'styles'

const BottomNotes = () => {
    return (
        <>
            <hr />
            <Text className="caption-sm">
                UPS&reg;, the UPS Shield trademark, the UPS Ready mark, the UPS
                Developer Kit mark and the Color Brown are trademarks of United
                Parcel Service of America, Inc. All Rights Reserved.
            </Text>
        </>
    )
}

export default BottomNotes
