import React, { useState } from 'react'
import { Flex } from 'styles'
import BottomNotes from 'components/dashboard/integrations/containers/CarrierWizard/components/BottomNotes'
import { fieldCheckboxRC as FieldCheckboxRC } from 'components/ui/form/renderers'
import StepIndicator from 'components/dashboard/integrations/containers/CarrierWizard/components/StepIndicator'

const UPS_POLICY_AGREEMENT =
    'https://www.ups.com/us/en/support/shipping-support/legal-terms-conditions.page'

export const UPSAgreementStep = ({
    onAgree,
    totalSteps,
    currentStep,
    title,
}) => {
    const [agreed, setAgreed] = useState(false)

    return (
        <>
            <h3>{title}</h3>

            {totalSteps && currentStep && (
                <StepIndicator
                    total={totalSteps}
                    current={currentStep}
                    failure={0}
                    warning={0}
                />
            )}

            <h4>License Agreement</h4>

            <div className="info-msg">
                <AgreementField value={agreed} onChange={setAgreed} />
            </div>

            <button
                id="upsAgreeButton"
                className="agree btn btn-primary"
                type="button"
                onClick={onAgree}
                disabled={!agreed}
            >
                Continue
            </button>

            <BottomNotes />
        </>
    )
}

export const AgreementField = ({ value, onChange }) => {
    return (
        <Flex alignItems="center" justifyContent="center" className="mt-2 mb-2">
            <FieldCheckboxRC
                title={
                    <span>
                        I Agree to the{' '}
                        <a target="_blank" href={UPS_POLICY_AGREEMENT}>
                            UPS Terms of Service and Privacy Policy
                        </a>
                    </span>
                }
                input={{
                    name: 'details',
                    type: 'checkbox',
                    checked: value,
                    onChange: (event) => {
                        onChange(event.target.checked)
                    },
                }}
            />
        </Flex>
    )
}
