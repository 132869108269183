//
import React from 'react'
import railsRoutes from 'utils/railsRoutes'
import { upgradePlanURL } from 'utils/urls'

export function ValidationActions({
    carrierEntity,
    routes,
    carrierDisabled,
    buttonOnly,
}) {
    const configureCarrier = () => {
        window.location = routes.edit_ratesmgr_carrier_path(carrierEntity.id)
    }

    const displayUpgradePlan = !buttonOnly && carrierDisabled
    return (
        <>
            <ConfigureButton onClick={configureCarrier} />
            {displayUpgradePlan && (
                <div>
                    <a href={upgradePlanURL()} className="btn">
                        Upgrade My Plan
                    </a>
                </div>
            )}
        </>
    )
}

const ConfigureButton = ({ onClick }) => (
    <button onClick={onClick} type="button" className="btn btn-primary">
        Configure This Carrier
    </button>
)

export default railsRoutes(ValidationActions)
