import { useSelector } from 'react-redux'

export default function useCarrierTitle(name: string): string {
    const integrations = useSelector<
        ReduxRootState,
        ReduxRootState['app']['integrations']['available_integrations']
    >(
        ({ app: { integrations: { available_integrations } = {} } }) =>
            available_integrations
    )

    const carrier =
        integrations && integrations.find((carrier) => carrier.name === name)

    if (carrier) {
        return carrier.title
    } else {
        return name
    }
}

export function useCarrierTitleResolver() {
    const integrations = useSelector<
        ReduxRootState,
        ReduxRootState['app']['integrations']['available_integrations']
    >(
        ({ app: { integrations: { available_integrations } = {} } }) =>
            available_integrations
    )

    return (name: string) => {
        const carrier =
            integrations &&
            integrations.find((carrier) => carrier.name === name)

        if (carrier) {
            return carrier.title
        } else {
            return name
        }
    }
}
